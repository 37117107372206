import React, { useEffect } from "react";
import { Box, Center, Link, Text, Image, VStack, Input, Button, ScaleFade, Alert, AlertIcon, HStack, InputGroup, InputRightElement, Icon } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useGetAccessTokenMutation } from "../../state/services/auth";
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RootState } from "../../state/store";
import { Navigate } from "react-router-dom";
import { loginSession } from "../../helpers/sessionHelper";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { FaArrowLeft, FaBuilding, FaGlobeEurope, FaTruck } from "react-icons/fa";


const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get("returnUrl");

  const { register, handleSubmit } = useForm();
  const { accessToken } = useSelector((state: RootState) => state.auth)
  const [getAccessToken, { isError, isLoading }] = useGetAccessTokenMutation();
  const dispatch = useDispatch();

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const isAccessTokenPresent = accessToken?.length > 0;

  const onSubmit = async data => {
    await getAccessToken(data).unwrap()
      .then((response) => {
        const token = response['token'];
        const refreshToken = response['refreshToken'];

        loginSession(token, refreshToken, dispatch)
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (isAccessTokenPresent) {
      navigate(returnUrl || '/dashboard')
    }
  }, [isAccessTokenPresent]);

  return (
    <>
      <Center pt={10}>

      </Center>

      <Box mt={'10vh'}>

        <Center>
          <VStack bg={'white'} p={10} borderRadius={'lg'} boxShadow={'lg'}>
            <Image w={'220px'} mb={10} src={'/images/logo.png'} />

            <Text fontSize={'md'} color={'#2b343f'}>Molimo unesite podatke kako bi se prijavili na platformu.</Text>
            <Box w={'100%'}>
              {isError &&
                <ScaleFade initialScale={0.9} in={isError}>
                  <Alert mt={5} mb={5} fontSize={'sm'} status='error'>
                    <AlertIcon />
                    Netačni podaci.
                  </Alert>
                </ScaleFade>}

              {isAccessTokenPresent &&
                <ScaleFade initialScale={0.9} in={isAccessTokenPresent}>
                  <Alert mt={5} fontSize={'sm'} status='success'>
                    <AlertIcon />
                    Uspješno! Molimo pričekajte...
                  </Alert>
                </ScaleFade>}

              <Box mt={5}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Text color={'grey'} fontWeight={'bold'} fontSize={'xs'}>Email:</Text>
                  <Input type={'email'} autoComplete={'username'} fontSize={'md'} {...register('email', { required: true })} />

                  <Text mt={'5'} color={'grey'} fontWeight={'bold'} fontSize={'xs'}>Šifra:</Text>

                  <InputGroup size='md'>
                    <Input
                      {...register('password', { required: true })}
                      pr='4.5rem'
                      fontSize={'md'}
                      type={show ? 'text' : 'password'}
                      placeholder='Unesite šifru...'
                      autoComplete={'current-password'}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' mr={2} size='sm' onClick={handleClick}>
                        {show ? 'Sakrij' : 'Prikaži'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <Button isDisabled={isLoading} type='submit' size={'md'} w={'100%'} mt={'8'} colorScheme={'blue'}>
                    {isLoading ? 'Prijava u toku...' : 'Prijava'}
                  </Button>
                  <HStack mt={3}>
                    <Button fontSize={'sm'} size={'md'} w={'full'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>Nazad</Button>
                    <Button leftIcon={<Icon as={FaBuilding} />} colorScheme={'gray'} fontSize={'sm'} size={'md'} w={'full'} as={ReachLink} to={'/register'}>Registrujte se</Button>
                  </HStack>
                </form>

                <Center>
                  <HStack mt={5} fontSize={'xs'}>
                    <Link as={ReachLink} to={'/forgot'} fontSize={'xs'}>Zaboravili ste šifru?</Link>

                    <Link href={'/privacy'}>Politika privatnosti</Link>
                    <Link href={'/terms'} ml={5}>Uslovi korištenja</Link>
                  </HStack>
                </Center>

              </Box>
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  )
};

export default Login;