import { AlertDialog, Text, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, Spacer, HStack, Button, AlertDialogFooter, useDisclosure } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useCloseTransportationOfferMutation } from "../../../state/services/transportation/offers";
import { IOffer, IRequest } from "../../../resources/interfaces";
import { useCloseRequestMutation, useGetRequestQuery } from '../../../state/services/requests';


interface IProps {
  disclosure: any;
  offer: IOffer;
};

const CloseOffer = ({ offer, disclosure }: IProps) => {
  const { register, control, handleSubmit, getValues, watch } = useForm();

  const [closeRequest] = useCloseTransportationOfferMutation();

  const { isOpen, onClose } = disclosure;

  const cancelRef = React.useRef();

  const handleCloseRequest = (data) => {
    closeRequest({
      id: parseInt(offer.id.toString()),
    });

    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <form onSubmit={handleSubmit(handleCloseRequest)}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Zatvori ponudu
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Da li ste sigurni da želite zatvoriti ponudu?</Text>

              <Spacer h={'20px'} />

            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Odustani
              </Button>
              <Button colorScheme='red' onClick={handleSubmit(handleCloseRequest)} ml={3}>
                Zatvori
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default CloseOffer;