import { AlertDialog, Text, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Spacer, InputGroup, Input, InputRightAddon, AlertDialogFooter, Button, useToast, Flex, Box, HStack, Icon, VStack, useDisclosure, Stack, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaQuestionCircle, FaTimesCircle } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { IRequest, TRequestState } from "../../../resources/interfaces";
import CreateRequestOffer from "./CreateRequestOffer";

interface IProps {
  request: IRequest;

};

const RequestOffer = ({ request }: IProps) => {

  const createRequestOfferDisclosure = useDisclosure();
  const offer = request?.offer;

  return (
    <>
      <CreateRequestOffer disclosure={createRequestOfferDisclosure} request={request} />

      {offer == null && request?.state != TRequestState.Closed &&
        <Flex justifyContent={'center'}>
          <VStack spacing={10}>
            <Button onClick={createRequestOfferDisclosure.onOpen} py={5} w={['full', 'sm']} leftIcon={<Icon mr={5} as={FiTruck} />} colorScheme={'red'}>Pošalji ponudu za vožnju</Button>

            <Tooltip hasArrow label={'Zbog zaštite privatnosti, podaci o Vašoj firmi poput imena, adrese i broja telefona će biti vidljivi samo firmi koja je ponudila robu ili teret. Vaša ponuda i cijena neće biti vidljiva drugim prijevoznicima.'}>
              <Text cursor={'pointer'}>
                <b>Napomena</b>: Podaci o Vašoj ponudi neće biti javno dostupni drugim prijevoznicima.
                <Icon ml={3} as={FaQuestionCircle} />
              </Text>
            </Tooltip>
          </VStack>

        </Flex>
      }

      {offer &&
        <>
          <Box border={'solid 1px gold'} _hover={{ boxShadow: 'md' }} cursor={'pointer'} w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
            <Stack direction={['column', 'row']} justifyContent={'space-evenly'} alignItems={['center', '']}>
              <VStack>
                <Text>Vaša ponuda</Text>
                <Text fontSize={'xx-large'} fontWeight={'bold'}>{offer?.amount}</Text>
                <Text fontSize={'sm'} color={'gray.500'}>Poslano prije {offer?.createdAtBefore}.</Text>
              </VStack>

            </Stack>
          </Box>
        </>
      }
    </>
  );
}

export default RequestOffer;