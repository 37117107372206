import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';

const Terms = () => {
  return (
    <>

      <Box>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>Nazad</Button>


            <Spacer h={'30px'} />

            <Text fontSize={'xl'} fontWeight={'light'}>Uslovi korištenja</Text>

            <Divider my={5} />

            <Heading as="h2" size="md" mt={8} mb={4}>
              1. Opšte
            </Heading>

            <Text>
              U ovim opštim uslovima korištenja (u daljem tekstu "Uslovi") sljedeći izrazi imaju sljedeća značenja:

              <br /><br />

              "Korisnik" označava preduzeće koje koristi Platformu za potrebe svojih transportnih operacija.

              <br /><br />

              "Platforma" označava platformu za berzu transporta koju pruža ova web stranica, uključujući sve povezane web stranice, aplikacije, alate, funkcije i usluge koje su dostupne putem te platforme.

              <br /><br />

              "Korisnički račun" označava pojedinačni korisnički račun koji je otvoren na Platformi kako bi Korisnik mogao pristupiti i koristiti sve funkcije i usluge dostupne putem Platforme.

              <br /><br />

              Ovi uslovi važe za sve vrste korištenja platforme. Korištenjem platforme, prihvatate Uslove u potpunosti, čak i ako ne otvorite Korisnički nalog.

              <br /><br />

              Pristup Uslugama neće biti moguć ako se Uslovi ne prihvate u cjelini. Korisnici nemaju pravo da prihvate samo dio Uslova. Svi Korisnici prihvataju da se njihovi lični podaci obrađuju u skladu sa Politikom zaštite podataka o ličnosti.

              <br /><br />

              Ugovor za pružanje usluga prijevoza je između prijevozničkog preduzeća i proizvodnog/trgovinskog preduzeća i eTransport.ba platforma nije ugovorna strana ovih aranžmana odnosno ugovora između njih.

              <br /><br />

              U slučaju da Korisnik ne poštuje bilo koji od utvrđenih Uslova, eTransport.ba platforma zadržava pravo da povuče Korisnički račun tog Korisnika i da obustavi ili uskrati sve Usluge tom Korisniku bez prethodnog obavještenja.

            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              2. Predmet uslova korištenja
            </Heading>

            <Text>
              Predmet ovih opštih uslova korištenja (u daljem tekstu "Uslovi") je definisanje uslova korištenja Platforme za berzu transporta (u daljem tekstu "Platforma") koju pruža ova web stranica Korisniku, kao preduzeću koje želi da koristi usluge ove Platforme za svoje transportne operacije.

              <br /><br />

              Platforma pruža usluge elektronskog posredovanja u vezi sa ponudom i potražnjom za transportom tereta, uključujući pretragu dostupnih transportnih sredstava i vozila, slanje ponuda za transportnu operaciju između registrovanih Korisnika Platforme.

              <br /><br />

              Korištenjem Platforme Korisnik potvrđuje da je upoznat sa ovim Uslovima i da se slaže sa njima u potpunosti.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              3. Registracija i nalog korisnika
            </Heading>

            <Text>
              Korisnik se registruje za korištenje Platforme stvaranjem Korisničkog računa na Platformi i pružanjem tačnih i potpunih informacija o sebi i svom preduzeću. Korisnik mora biti špeditersko, prijevozničko ili proizvodno preduzeće kako bi mogao koristiti Platformu.

              <br /><br />

              Nakon registracije, Korisnik će dobiti obavještenje o potvrdi putem e-maila, a registracija će biti pregledana i odobrena od strane tima koji upravlja Platformom. Ukoliko tim odbije registraciju Korisnika, razlog će biti dostavljen Korisniku putem unesenog e-maila ili telefona.

              <br /><br />

              Korisnik je odgovoran za održavanje tajnosti svojih prijava za pristup Platformi, uključujući šifre koje su vezane za Korisnički račun, i za ograničivanje pristupa svom računu. Korisnik se obavezuje da će nas odmah obavijestiti o svakom neovlaštenom pristupu, korištenju ili sumnjivom aktivitetu koji se odnose na njegov Korisnički račun.

            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              4. Obaveze korisnika
            </Heading>

            Korisnik sam provjerava pravilnost dospjelih podataka i unosa trećih lica. Poruke koje korisnici šalju i prikazuju preko platforme eTransport.ba ne predstavljaju izjave ili stavove platforme eTransport.ba, niti ih eTransport.ba platforma odobrava ili prihvata kao svoje.

            <br /><br />

            Korisnik oslobađa eTransport.ba platformu svih odgovornosti nastalih kao posljedica upotrebe platforme od strane korisnika koja nije u skladu sa ovim ugovorom. Osim toga, korisnik oslobađa eTransport.ba platformu svih zahtjeva i kazni od strane drugih korisnika, organa ili ostalih trećih lica, koji nastanu time što on koristeći platformu nudi ili pruža usluge za koje ne raspolaže ili ne raspolaže u dovoljnoj mjeri za to propisanim dozvolama, osiguranjima, licencama itd.

            <br /><br />

            Opasnost od ne prenošenja ili pogrešnog prenošenja podataka pada na teret korisnika onog trenutka kada podaci izađu iz zone uticaja eTransport.ba platforme.

            <br /><br />

            Korisnik sam snosi odgovornost za korištenje svog pristupa platformi, zabranjena mu je svaka vrsta zloupotrebe, kao i svaki pokušaj da informacije učitava sam, bez ovlaštenja ili preko trećih lica ili da vrši zahtjeve u sistemu platforme. U takvim slučajevima on snosi nastale troškove, što uključuje i izdatke platforme eTransport.ba za provjeru uređaja i/ili izdatke koji su posljedica nedostataka i smetnji, koji leže u domenu odgovornosti korisnika. Korisnik mora odmah da obavijesti eTransport.ba platformu o mogućnosti pristupa neovlašćenih trećih lica ili o drugim vrstama narušavanja bezbednosti podataka za koja je saznao ili na koje sumnja, kao i o postavljenim zahtjevima trećih lica protiv njegovog korištenja platforme i da odmah preduzme sve ostale potrebne radnje.

            <br /><br />

            Korisnik garantuje eTransport.ba platformi da su zaposleni koje je angažovao, odnosno saradnici koji rade za njega zaposleni u skladu sa važećim zakonskim uslovima i oslobađa eTransport.ba platformu od svake odgovornosti, svih kazni i primjerenih advokatskih troškova u vezi sa tim. Korisnik garantuje eTransport.ba platformi da svoje usluge u svakom trenutku pruža uz poštovanje odgovarajućih važećih propisa i da će to na zahtjev eTransport.ba platforme neodložno dokazati odgovarajućim dokumentima.


            <Heading as="h2" size="md" mt={8} mb={4}>
              5. Odgovornosti platforme
            </Heading>

            Ni eTransport.ba platforma niti povezane stranice ne pružaju transportne i prijevozničke usluge. Platforma je komunikacijska platforma za Korisnike u cilju međusobnog dogovaranja. Platforma nema nikakve veza sa prijevozom, odredištima ili naknadama za usluge koje se ugovaraju. Dogovor o transportnim uslugama pravi se između Korisnika platforme, odnosno, prijevozničkih i proizvodnih/trgovinskih preduzeća. eTransport.ba platforma nije strana ni u jednom dogovoru ili transakciji između Korisnika, i platforma nije odgovorna ni za kakvu situaciju koja može da nastane u vezi sa zahtjevima i ponudama na transportne usluge između Korisnika. Platforma ne postupa niti će postupati kao zastupnik nijednog Korisnika.

            <br /><br />

            Platforme ne odgovara za bilo kakve saobraćajne nezgode ili saobraćajne nesreće. Platforma ne odgovara za bilo kakve sporove ili incidentne situacije koje mogu nastati između Korisnika platforme kao i između Korisnika i trećih lica. Svako kršenje ovih Uslova povlači za sobom trenutnu obustavu Korisničkog računa Korisnika i tom Korisniku može da se onemogući dalji pristup svim Uslugama.

            <br /><br />

            Navedene informacije u opisu proizvoda, prospektima i uputstvima za upotrebu pruženim od strane eTransport.ba platforme predstavljaju preporuke koje nisu obavezujuće. eTransport.ba platforma ne snosi daljnju odgovornost. Posebno, eTransport.ba platforma ne preuzima odgovornost za tačnost, potpunost i ažuriranost informacija koje su postavili drugi korisnici, niti za kompatibilnost platforme s računarskom opremom korisnika ili veza za prijenos podataka koje se koriste. Korisnik je svjestan da kompleksne online platforme poput eTransport.ba platforme, sukladno tehničkim standardima, nikada ne mogu biti potpuno besprijekorne. Stoga, eTransport.ba platforma ne garantuje potpunu odsutnost grešaka niti odgovarajuću dostupnost, već samo da softver na platformi neće imati nedostatke koji bi u značajnoj mjeri ugrozili dogovorenu upotrebu prema ugovoru.

            <br /><br />

            Izmjene hiperveza i informacija dostupnih na internet stranicama trećih strana preko hiperveza na početnoj stranici i platformi eTransport.ba ne čine sastavni dio ugovornih obaveza niti služe za detaljnije pojašnjenje tih obaveza. Uređivanje hiperveza i sadržaja stranica dostupnih putem hiperveza nije stalno pod kontrolom eTransport.ba platforme. Iz tog razloga, eTransport.ba platforma ne preuzima odgovornost za sadržaj ili tačnost tih informacija.

            <br /><br />

            Platforma neće biti odgovorna ni prema jednom Korisniku ni za kakav poslovni, finansijski ili ekonomski gubitak niti za bilo kakav naknadni ili posredni gubitak kao što je gubitak reputacije, gubitak posla, gubitak dobiti, gubitak očekivane uštede ili gubitak prilike, nastao kao rezultat korištenja usluga koje pruža eTransport.ba platforma.

            <br /><br />

            eTransport.ba platforma nema odgovornost u bilo kojem slučaju nastanka materijalne ili nematerijalne štete niti ima odgovornost za bilo koju nesreću poput smrti ili druge nesreće. S obzirom na to da je Korisnik obavezan da poseduje važeće dozvole za obavljanje transportnih usluga i da se usluga platforme ograničava samo na pružanje mogućnosti kontakta tj. povezivanje špediterskih/prijevozničkih i proizvodnih/trgovinskih preduzeća i ne može da nadzire nijednu transportnu uslugu, Korisnik prihvata da su prethodno navedena ograničenja odgovornosti Platforme razumna.

            <Heading as="h2" size="md" mt={8} mb={4}>
              6. Naknade i plaćanja
            </Heading>

            Sve specifičnosti vezane za plaćanje i naknadu za korištenje eTransport.ba platforme jasno su određene i detaljno opisane u posebnom ugovoru o korištenju platforme između eTransport.ba Platforme i Korisnika. Ovaj ugovor ne precizira cijene, načine plaćanja, periodičnost plaćanja, kao i sve druge relevantne detalje koji se odnose na financijske obaveze korisnika za pristup i korištenje eTransport.ba platforme.

            <Heading as="h2" size="md" mt={8} mb={4}>
              7. Zaštita podataka
            </Heading>

            Obrađivanje i zaštita podataka na eTransport.ba platformi uređena je Politikom privatnosti koja se može pronaći na sljedećoj <Link href="/privacy">vezi</Link>.

            <Heading as="h2" size="md" mt={8} mb={4}>
              8. Izjava o odricanju odgovornosti
            </Heading>

            eTransport.ba platforma za berzu transporta pruža usluge u dobroj namjeri i s ciljem olakšavanja povezivanja korisnika u svrhu razmjene informacija o transportu. Međutim, eTransport.ba platforma izričito odriče svaku odgovornost za bilo kakve gubitke, štete ili nepravilnosti koje mogu proizaći iz korištenja platforme.

            <br /><br />

            eTransport.ba platforma ne može garantirati tačnost, potpunost ili pouzdanost informacija koje su dostupne na platformi. Korisnici prepoznaju da su informacije pružene od strane drugih korisnika i trećih strana, te se preporučuje da korisnici koriste vlastitu procjenu i razum pri donošenju odluka na temelju tih informacija. eTransport.ba platforma ne snosi odgovornost za bilo kakve gubitke ili štete nastale kao rezultat oslanjanja na te informacije.

            <br /><br />

            Sve transakcije, sporazumi i dogovori koji se ostvaruju između korisnika putem eTransport.ba platforme su isključivo između korisnika. eTransport.ba platforma nije uključena u te transakcije i ne preuzima odgovornost za bilo kakve gubitke, štete ili sporove koji mogu proizaći iz takvih transakcija ili sporazuma.

            <br /><br />

            eTransport.ba platforma nastoji osigurati kontinuiranu dostupnost i pouzdanost platforme. Međutim, platforma može biti podložna tehničkim prekidima, greškama ili nestabilnostima koje mogu privremeno ometati pristup ili korištenje platforme. eTransport.ba platforma ne snosi odgovornost za bilo kakve gubitke ili štete koje mogu proizaći iz takvih prekida ili grešaka.

            <br /><br />

            eTransport.ba platforma može sadržavati veze ili hiperveze do trećih strana ili vanjskih web stranica koje nisu pod kontrolom platforme. eTransport.ba platforma ne preuzima odgovornost za sadržaj, točnost ili dostupnost tih vanjskih veza i ne snosi odgovornost za bilo kakve gubitke ili štete koje mogu proizaći iz korištenja tih vanjskih veza.

            <br /><br />

            Korisnici svjesno koriste eTransport.ba platformu na vlastitu odgovornost.

            <Heading as="h2" size="md" mt={8} mb={4}>
              9. Izmjene uslova korištenja
            </Heading>

            eTransport.ba Platforma zadržava pravo da povremeno, bez najave, promijeni ili modifikuje svoja pravila. Važne promjene i dopune uslova može obavijestiti korisnike putem dostavljenog e-maila. Korištenje bilo kojeg sadržaja na platformi podrazumijeva da ste upoznati s najnovijim pravilima.
          </Box>
        </Center>
      </Box>

      <Spacer h={'100px'} />

    </>
  )
};

export default Terms;