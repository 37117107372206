import React, { useEffect } from "react";
import { Box, Center, Link, Text, Image, VStack, Input, Button, ScaleFade, Alert, AlertIcon, HStack, InputGroup, InputRightElement, PinInput, PinInputField } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation, useGetAccessTokenMutation, useVerifyForgotPasswordMutation } from "../../state/services/auth";
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RootState } from "../../state/store";
import { Navigate } from "react-router-dom";
import { loginSession } from "../../helpers/sessionHelper";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";


const Forgot = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get("returnUrl");

  const [email, setEmail] = React.useState(null)
  const [code, setCode] = React.useState('');

  const { register, handleSubmit } = useForm();
  const [getToken, { isError, isLoading, isSuccess }] = useForgotPasswordMutation();

  const [show, setShow] = React.useState(false)

  const onSubmit = data => {
    setEmail(data.email);

    getToken(data);
  };

  return (
    <>
      <Center pt={10}>

      </Center>

      <Box mt={'10vh'}>

        <Center>
          <VStack bg={'white'} p={10} borderRadius={'lg'} boxShadow={'lg'}>

            <Image w={'220px'} mb={10} src={'/images/logo.png'} />

            <Text fontSize={'md'} color={'#2b343f'}>Molimo unesite E-Mail kako bi dobili kod za promjenu šifru.</Text>
            <Box w={'100%'}>



              {isSuccess &&
                <ScaleFade initialScale={0.9} in={isSuccess}>
                  <Alert mt={5} fontSize={'sm'} status='success'>
                    <AlertIcon />
                    Uspješno! <br />Na vašu e-mail adresu poslali smo daljnje upute za promjenu šifre. <br />Molimo provjerite svoj e-mail inbox i slijedite korake kako biste ponovno pristupili svom računu.
                  </Alert>

                  <Button mt={3} size={'md'} w={'full'} as={ReachLink} to={'/login'} leftIcon={<FaArrowLeft />}>Nazad</Button>

                </ScaleFade>}

              {!isSuccess &&
                <Box mt={5}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Text color={'grey'} fontWeight={'bold'} fontSize={'xs'}>Vaš E-Mail:</Text>
                    <Input autoComplete="email" type={'email'} fontSize={'sm'} {...register('email', { required: true })} />

                    <Button isDisabled={isLoading} type='submit' size={'md'} w={'100%'} mt={'8'} colorScheme={'blue'}>Pošalji</Button>
                    <Button mt={3} size={'md'} w={'full'} as={ReachLink} to={'/login'} leftIcon={<FaArrowLeft />}>Nazad</Button>

                  </form>

                  <Center>
                    <HStack mt={5} fontSize={'xs'}>
                      <Link href={'/privacy'}>Politika privatnosti</Link>
                      <Link href={'/terms'} ml={5}>Uslovi korištenja</Link>
                    </HStack>
                  </Center>
                </Box>
              }
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  )
};

export default Forgot;