import { Box, Image, HStack, Icon, Spacer, Text, Tooltip, Button, Avatar, AvatarGroup, Stack, Center, Table, Tbody, Td, Th, Thead, Tr, TableContainer, VStack, Tag, Show, Divider, AvatarBadge, Badge, Input, InputGroup, InputLeftAddon, Hide, Flex } from '@chakra-ui/react'
import * as React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { FaArrowRight, FaCalendar, FaCircle, FaClosedCaptioning, FaCoins, FaEllipsisV, FaEye, FaFire, FaFlag, FaLocationArrow, FaMapMarker, FaMapMarkerAlt, FaMarker, FaMoneyBill, FaRegCalendarAlt, FaSearch, FaSnowflake, FaTruck, FaTruckLoading, FaTruckMoving } from 'react-icons/fa';
import { CircleFlag } from 'react-circle-flags';
import { useState } from 'react';
import Paginator from '../../components/Common/Paginator';
import { displayDate } from '../../helpers/dateHelper';
import { useGetTransportationRequestsQuery } from '../../state/services/transportation/requests';
import { IMetaRequestFreightRequest, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { GrMoney } from 'react-icons/gr';
import { TbGlass } from 'react-icons/tb';
import { chakraComponents, Select } from 'chakra-react-select';
import { useGetVehiclesQuery } from '../../state/services/vehicles';

const vehicleComponents = {
  Control: (props) => (
    <chakraComponents.Control {...props}>
      <Flex w={'full'} h={'38px'}>
        {props.children}
      </Flex>
    </chakraComponents.Control>
  ),
  ValueContainer: (props) => (
    <chakraComponents.ValueContainer {...props}>
      {props.children[0]?.length > 0 && <Text cursor={'pointer'}>Odabrano {props.children[0]?.length} vozila.</Text>}
      {!props.hasValue && <Text cursor={'pointer'}>Odaberite vozilo...</Text>}

      {props.children[1]}
    </chakraComponents.ValueContainer>
  )
};

const VehicleSelect = ({ setSelectedVehicles, selectedVehicles, menuPlacement = 'bottom' }) => {
  const { data: vehiclesData } = useGetVehiclesQuery();

  return (
    <>
      <Select
        isMulti
        closeMenuOnScroll={false}
        onChange={(e) => setSelectedVehicles(e)}
        value={selectedVehicles}
        options={vehiclesData?.vehicles?.map((v) => ({ value: v.id, label: v.name }))}
        tagVariant={'solid'}
        placeholder=""
        closeMenuOnSelect={false}
        menuPlacement={menuPlacement}
        selectedOptionStyle="check"
        hideSelectedOptions={false}
        components={vehicleComponents}
      />
    </>
  )
};
export default VehicleSelect;