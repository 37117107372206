import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Spinner, Center, Skeleton, SkeletonCircle, SkeletonText, Show, useDisclosure, AvatarBadge, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiStar, FiThumbsDown, FiThumbsUp, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IRequestOffer, TBusinessType, TDepartmentTechnicalName, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowLeft, FaArrowRight, FaCircle, FaFire, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useCloseRequestMutation, useGetRequestQuery } from '../../state/services/requests';
import { CircleFlag } from 'react-circle-flags';
import { useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import CloseRequest from './Request/CloseRequest';
import CreateRequestOffer from '../TransportationCompany/Request/CreateRequestOffer';
import TransportationRequestOffer from '../TransportationCompany/Request/RequestOffer';
import RequestOffers from './Request/RequestOffers';
import RequestInformation from '../Request/RequestInformation';


const Request = () => {
  let { id } = useParams();
  const closeRequestDisclosure = useDisclosure();
  const [selectedRequestOffer, setSelectedRequestOffer] = React.useState(null);

  const { currentUser } = useSelector((state: RootState) => state.user)
  const { data, isLoading, isSuccess } = useGetRequestQuery(parseInt(id), { refetchOnMountOrArgChange: true, pollingInterval: 5000 });

  const request = data?.request;

  const closeRequest = (requestOffer: IRequestOffer) => {
    setSelectedRequestOffer(requestOffer);
    closeRequestDisclosure.onOpen();
  };

  return (
    <>
      <Spacer h={'20px'} id={'view-placement'} />
      {isLoading && <>
        <Center>
          <Spinner />
        </Center>
      </>}

      {
        isSuccess && <>
          <CloseRequest disclosure={closeRequestDisclosure} selectedRequestOffer={selectedRequestOffer} request={request} />

          <HStack justifyContent={'space-between'} id={'view-placement'}>
            <Button size={'sm'} as={ReachLink} to={'/dashboard'} leftIcon={<FaArrowLeft />}>Nazad</Button>
            {request.state == TRequestState.Created && <Button size={'sm'} onClick={closeRequestDisclosure.onOpen} colorScheme={'red'} leftIcon={<FaTimesCircle />}>Zatvori zahtjev</Button>}
          </HStack>
          <Spacer h={'20px'} />

          <RequestInformation request={request} />

          <RequestOffers closeRequest={closeRequest} request={request} />
        </>
      }

      <Spacer h={'150px'} />

    </>
  )
};

export default Request;