import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Input, Checkbox, InputLeftElement, InputGroup, InputRightAddon, Textarea, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, useToast, Spinner, Center, Show } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TDepartmentTechnicalName } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaCheck, FaCheckCircle, FaCircle, FaFire, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { GiShatteredGlass } from 'react-icons/gi';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import {
  AsyncCreatableSelect,
  AsyncSelect,
  chakraComponents,
  CreatableSelect,
  Select,
} from "chakra-react-select";
import { useGetVehiclesQuery } from '../../state/services/vehicles';
import Vehicle from '../StandardCompany/CreateRequest/Vehicle';
import RequestService from '../StandardCompany/CreateRequest/Service';
import { Controller, useForm } from 'react-hook-form';
import Service from '../StandardCompany/CreateRequest/Service';
import { CircleFlag } from 'react-circle-flags';
import countries from '../../resources/eu_countries';
import { iconComponents } from '../../resources/icon_select';
import CountrySelect from '../../components/Form/CountrySelect';
import { useEffect } from 'react';
import { useCreateTransportationOfferMutation } from '../../state/services/transportation/offers';
import { todayDate } from '../../helpers/dateHelper';

const CreateOffer = () => {
  const { register, handleSubmit, control, getValues } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef()
  const toast = useToast();
  const navigate = useNavigate();


  const { data: vehiclesData } = useGetVehiclesQuery();
  const [createOffer, { isLoading, isSuccess, isError }] = useCreateTransportationOfferMutation();

  const getData = () => {
    const data = getValues();

    data.vehicles = Object.keys(data.vehicles).filter((key) => data.vehicles[key])?.map((k) => k.replace('vehicle_id_', ''));
    data.loading_country = data.loading_country.value;
    data.unloading_country = data.unloading_country.value;
    data.loading_type = data.loading_type.value;

    return data;
  }
  const onSubmit = () => {
    if (getData().vehicles.length <= 0) {
      toast({
        title: 'Greška!',
        description: "Molimo odaberite bar jedno vozilo.",
        status: 'warning',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })

      return;
    }

    onOpen();
  }

  const onConfirm = () => {
    createOffer(getData()).then((data) => {
      const id = data?.data?.offer?.id;

      if (id) {
        navigate(`/app/offers/${id}`)
      }
    });
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Greška!',
        description: "Dogodila se greška prilikom izrade zahtjeva. Molimo pokušajte ponovo.",
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isError]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {!isLoading &&
              <>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Potvrda!
                </AlertDialogHeader>

                <AlertDialogBody>
                  Da li ste sigurni da želite napraviti novu ponudu?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Odustani
                  </Button>
                  <Button leftIcon={<Icon as={FaCheckCircle} />} onClick={onConfirm} colorScheme='green' ml={3}>
                    Nastavi
                  </Button>
                </AlertDialogFooter>
              </>}

            {isLoading && <>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Molimo pričekajte... Zahtjev je u izradi.
              </AlertDialogHeader>
              <AlertDialogBody>
                <Center>
                  <Spinner />
                </Center>

                <Spacer h={'20px'} />
              </AlertDialogBody>
            </>}

          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Spacer h={'20px'} />
      <HStack justifyContent={'space-between'}>
        <Button size={'sm'} as={ReachLink} to={'/app/dashboard'} leftIcon={<FaArrowLeft />}>Nazad</Button>
        <Button onClick={handleSubmit(onSubmit)} size={'sm'} colorScheme={'green'} leftIcon={<FaCheckCircle />}>Napravi ponudu</Button>
      </HStack>
      <Spacer h={'20px'} />
      <form onSubmit={handleSubmit(onSubmit)}>

        <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
          <HStack justifyContent={'space-between'} flexDirection={['column', 'row']} alignItems={['center', 'flex-end']}>
            <Box>
              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Preuzimanje</Text>
              <CountrySelect control={control} defaultValue={'BA'} name={'loading_country'} />
              <Spacer h={'10px'} />
              <HStack>
                <Input textTransform={'capitalize'} {...register('loading_city', { required: true })} placeholder={'Grad'}></Input>
                <Input textTransform={'capitalize'} {...register('loading_address')} placeholder={'Adresa'}></Input>
              </HStack>
            </Box>

            <Box pt={[3, 0]}>
              <Show above={'md'}>
                <Icon fontSize={'20px'} as={FaArrowRight} mb={'5px'} />
              </Show>
              <Show below={'md'}>
                <Icon fontSize={'20px'} as={FaArrowDown} mb={'5px'} />
              </Show>
            </Box>

            <Box>
              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dostava</Text>

              <CountrySelect control={control} defaultValue={'BA'} name={'unloading_country'} />
              <Spacer h={'10px'} />
              <HStack>
                <Input textTransform={'capitalize'} {...register('unloading_city', { required: true })} placeholder={'Grad'}></Input>
                <Input textTransform={'capitalize'} {...register('unloading_address')} placeholder={'Adresa'}></Input>
              </HStack>
            </Box>
          </HStack>
        </Box>

        <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
          <HStack flexDirection={['column', 'row']} alignItems={'flex-start'} spacing={[0, 10]}>
            <Box w={'full'}>
              <Box mb={5} >
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Opcija utovara</Text>
                <Controller
                  control={control}
                  name="loading_type"
                  defaultValue={{ value: 'ftl', label: 'FTL - puni utovar' }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <Select
                      options={[
                        { value: 'ftl', label: 'FTL - puni utovar' },
                        { value: 'ltl', label: 'LTL - zbirni transport' },
                      ]}
                      placeholder="Odaberite opciju..."
                      closeMenuOnSelect={true}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      selectedOptionStyle="check"
                    />
                  )} />
              </Box>

              <Box mb={5}>
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum polaska</Text>
                <Input {...register('loading_date', { required: true })} type={'date'} min={todayDate()} defaultValue={todayDate()}></Input>
              </Box>

              <Box mb={5}>
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Slobodan prostor (m³)</Text>
                <InputGroup>
                  <Input {...register('space')} />
                  <InputRightAddon children='m³' />
                </InputGroup>
              </Box>

              <Box mb={5}>
                <HStack>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj paleta</Text>
                    <Input {...register('pallets')} />
                  </Box>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj komada</Text>
                    <Input {...register('packages')} />
                  </Box>
                </HStack>
              </Box>

              <Service technicalName={'is_ramp'} control={control} name={'Dostupna rampa'} description={'Označite ovu opciju ukoliko imate rampu na vozilu.'} color={'green'} icon={FaTruckLoading} />
              <Service technicalName={'is_adr'} control={control} name={'ADR - Opasna roba'} description={'Označite ovu opciju ukoliko možete prevoziti ADR.'} color={'red'} icon={FaFire} />
              <Service technicalName={'is_frigo'} control={control} name={'FRIGO'} description={'Označite ovu opciju ukoliko imate vozilo sa hladnjačama.'} color={'blue'} icon={FaSnowflake} />
            </Box>
            <Box w={'full'}>
              <Textarea {...register('note')} placeholder={'Dodatna napomena...'}></Textarea>

              <Spacer h={'25px'} />

              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dostupna vozila</Text>

              {vehiclesData?.vehicles?.map((vehicle) => (
                <Vehicle id={vehicle.id} control={control} controlName={'vehicle'} key={vehicle.id} vehicle={vehicle} />
              ))}
            </Box>
          </HStack>
        </Box>
      </form>

      <Spacer h={'30px'} />

      {/* <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
        <iframe
          width={'100%'}
          height="250"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/directions?&origin=Sarajevo
              &destination=Tuzla&key=AIzaSyC8woYBVNCZEtNc5snPC_7OtHXVb8AVoGI">
        </iframe>
  </Box>*/}

    </>
  )
};

export default CreateOffer;