import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, FormErrorMessage, FormControl, InputRightElement } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope, FaLock, FaPhone, FaUniversity } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useCreateCompanyMutation } from "../../state/services/companies";
import SupportedCountrySelect from "../../components/Form/SupportedCountrySelect";
import { useDispatch, useSelector } from "react-redux";
import { useGetAccessTokenMutation } from "../../state/services/auth";
import { IUserLoginRequest } from "~/resources/interfaces";
import { loginSession } from "../../helpers/sessionHelper";
import { RootState } from "../../state/store";

const Register = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, control, getValues, formState: { errors } } = useForm();
  const [createCompany, { isSuccess, isLoading }] = useCreateCompanyMutation();

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const onSubmit = (data) => {
    data.country = data?.country?.value;
    data.business_type = data?.business_type?.value;

    createCompany(data);
  };

  const { accessToken } = useSelector((state: RootState) => state.auth)
  const [getAccessToken, { isError, isLoading: isLoadingAccessToken }] = useGetAccessTokenMutation();
  const dispatch = useDispatch();

  const isAccessTokenPresent = accessToken?.length > 0;

  useEffect(() => {
    if (isAccessTokenPresent) {
      navigate('/dashboard')
    }
  }, [isAccessTokenPresent]);


  useEffect(() => {
    if (isSuccess) {
      const userData: IUserLoginRequest = {
        email: getValues('email'),
        password: getValues('password')
      };

      getAccessToken(userData).unwrap()
        .then((response) => {
          const token = response['token'];
          const refreshToken = response['refreshToken'];

          loginSession(token, refreshToken, dispatch)
        })
        .catch((error) => { });
    }
  }, [isSuccess])

  return (
    <>


      <Box>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>Nazad</Button>

            <Spacer h={'30px'} />
            <HStack justifyContent={''}>
              <Image w={'220px'} src={'/images/logo.png'} />
              <Text fontSize={'xl'} fontWeight={'light'}>Registrujte se na platformu potpuno besplatno.</Text>
            </HStack>

            <Divider my={5} />

            {/*isSuccess && <Alert status='success'>
                <AlertIcon />
                Hvala! Uspješno ste se registrovali na našu platformu. Uskoro ćete biti kontaktirani putem telefona ili e-mail adrese.
              </Alert>
              */}

            {isSuccess && <Alert status='success'>
              <AlertIcon />
              Hvala! Uspješno ste se registrovali na našu platformu, te sada možete pristupiti platformi. Radujemo se suradnji s Vama.
            </Alert>
            }

            {!isSuccess &&
              <>

                <Stack direction={['column', 'row']} alignItems={'flex-start'} spacing={10}>
                  <VStack spacing={5} w={'full'} alignItems={'flex-start'}>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Naziv firme</Text>
                      <Input w={'full'} {...register('name', { required: true })} placeholder={'Vaša firma d.o.o.'} ></Input>
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Vrsta firme</Text>
                      <Controller
                        control={control}
                        name="business_type"
                        defaultValue={null}
                        rules={{ required: "Molimo odaberite vrstu Vaše firme." }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { error }
                        }) => (
                          <FormControl isInvalid={!!error}>
                            <Select
                              options={[
                                { value: 'standard', label: 'Proizvodnja, trgovina ili drugo' },
                                { value: 'transportation', label: 'Prijevoznik' },
                                { value: 'forwarder', label: 'Špediter' },
                              ]}
                              placeholder="Odaberite vrstu firme..."
                              closeMenuOnSelect={true}
                              ref={ref}
                              onChange={onChange}
                              name="business_type"
                              value={value}
                              selectedOptionStyle="check"
                            />
                            <FormErrorMessage>{error && error.message}</FormErrorMessage>

                          </FormControl>
                        )} />
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Telefon</Text>

                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          zIndex={0}
                          children={<FaPhone color='gray.300' />}
                        />
                        <Input w={'full'} type={'tel'} {...register('phone', { required: true })} placeholder='Broj telefona (+38762000000)' />
                      </InputGroup>
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>ID Broj</Text>

                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<FaUniversity color='gray.300' />}
                        />
                        <Input type={'text'} w={'full'} {...register('id_number')} placeholder={'ID Broj firme (nije neophodno)'} ></Input>
                      </InputGroup>

                      <Spacer h={'10px'} />
                    </Box>


                  </VStack>
                  <Box w={'full'}>
                    <VStack spacing={8}>

                      <Box w={'full'}>
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Adresa</Text>
                        <SupportedCountrySelect control={control} name={'country'} />

                        <Spacer h={'10px'} />
                        <HStack>
                          <Input {...register('city', { required: true })} placeholder={'Grad'} ></Input>
                          <Input {...register('address', { required: true })} placeholder={'Adresa'} ></Input>
                        </HStack>
                      </Box>

                      <Box w={'full'} mt={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box w={'full'}>
                            <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>E-Mail</Text>

                            <InputGroup>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<FaEnvelope color='gray.300' />}
                              />
                              <Input type='email' autoComplete={'username'} id={'email'} name={'email'} {...register('email', { required: true })} placeholder='E-Mail' />
                            </InputGroup>
                          </Box>

                          <Box mt={5} w={'full'}>
                            <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Šifra</Text>
                            <InputGroup size='md'>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                              />
                              <Input
                                {...register('password', { required: true, minLength: 6 })}
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                autoComplete={'new-password'}
                                placeholder='Šifra za pristup platformi...'
                              />
                              <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' mr={2} size='sm' onClick={handleClick}>
                                  {show ? 'Sakrij' : 'Prikaži'}
                                </Button>
                              </InputRightElement>
                            </InputGroup>
                            <Text fontSize={'sm'} mt={2} color={'gray'}>Minimalno 6 znakova.</Text>
                          </Box>
                        </form>
                      </Box>
                    </VStack>
                  </Box>
                </Stack>

                <Spacer h={'25px'} />

                <Checkbox isInvalid={errors?.terms?.type?.length > 0} {...register('terms', { required: true })} color={errors?.terms?.type?.length > 0 ? 'red' : 'black'}>Slažem se sa <Link target={'_blank'} textDecor={'underline'} as={ReachLink} to={'/terms'}>uslovima korištenja</Link> i <Link textDecor={'underline'} href={'/privacy'}>politikom privatnosti</Link>.</Checkbox>

                <Spacer h={'25px'} />

                <Button type={'submit'} isDisabled={isLoading} onClick={handleSubmit(onSubmit)} leftIcon={<FaCheckCircle />} colorScheme={'green'}>Registruj se</Button>
              </>}

            <Divider my={5} />

            {1 == 2 && <Text textAlign={'justify'} color={'gray.500'} fontSize={'sm'}>Nakon registracije na našu platformu, Vaš račun će biti pregledan kako bi se osiguralo da ispunjavate naše uslove korištenja. Nakon što račun bude pregledan, naš tim će stupiti u kontakt sa Vama putem e-maila ili telefona kako bi pružili dodatne informacije i podršku. Zahvaljujemo na interesovanju za našu platformu i radujemo se saradnji sa Vama.</Text>}

            <Text textAlign={'justify'} color={'gray.500'} fontSize={'sm'}>Nakon registracije na našu platformu, pristupni podaci za platformu će biti dostavljeni na navedeni e-mail u registracijskoj formi. Zahvaljujemo na interesovanju za našu platformu i radujemo se saradnji sa Vama.</Text>

            <Spacer h={'20px'} />
          </Box>
        </Center>
      </Box>

    </>
  )
};

export default Register;