import { AlertDialog, Icon, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Spacer, InputGroup, Input, InputRightAddon, AlertDialogFooter, Button, useToast, FormControl, ModalContent, Modal, ModalFooter, FormLabel, ModalBody, ModalCloseButton, ModalHeader, ModalOverlay, Alert, AlertIcon } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCreateRequestOfferMutation } from "../../../state/services/request_offers";
import { IRequest } from "../../../resources/interfaces";
import { isMobile } from "../../../helpers/viewportHelper";
import { FaInfo, FaTelegram } from "react-icons/fa";

interface IProps {
  request: IRequest;
  disclosure: any;
};

const CreateRequestOffer = ({ request, disclosure }: IProps) => {
  const cancelRef = React.useRef();
  const inputRef = React.useRef(null);
  const toast = useToast();
  const { register, control, handleSubmit } = useForm();
  const { ref, ...rest } = register('amount');

  const { isOpen, onOpen, onClose } = disclosure;

  const [createRequestOffer, { isSuccess: isSuccessCreateRequestOffer, isLoading }] = useCreateRequestOfferMutation();

  const onSubmit = (data) => {
    createRequestOffer({
      requestId: request.id.toString(),
      amount: data?.amount
    })
  };


  useEffect(() => {
    if (isSuccessCreateRequestOffer) {
      onClose();

      toast({
        title: 'Uspješno!',
        description: "Uspješno ste poslali ponudu.",
        status: 'success',
        duration: 9000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isSuccessCreateRequestOffer])

  return (
    <Modal
      initialFocusRef={inputRef}
      isOpen={isOpen}
      isCentered={!isMobile}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ponuda za transport</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          Molimo Vas da unesete cijenu ponude za uslugu transporta u sljedeće polje za unos:

          <Spacer h={'30px'} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <InputGroup size={'lg'}>
                <Input ref={(e) => {
                  ref(e)
                  inputRef.current = e
                }} {...rest} type='number' placeholder='npr. 250' />
                <InputRightAddon children='€ (EUR)' />
              </InputGroup>
            </FormControl>

          </form>
          <Spacer h={'10px'} />
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={isLoading} onClick={handleSubmit(onSubmit)} colorScheme='red' size={'md'} leftIcon={<Icon as={FaTelegram} />} ml={3}>
            Pošalji ponudu
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal >


  );
}

export default CreateRequestOffer;