import { HStack, Icon, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import countries from '../../resources/eu_countries';
import { Controller } from "react-hook-form";
import { iconComponents } from "../../resources/icon_select";

const SupportedCountrySelect = ({ control, name, defaultValue = null }) => {
  const mappedCountries = countries.filter((c) => ['BA', 'HR', 'RS', 'ME', 'SI', 'AL', 'MK'].includes(c.code)).map((country) => ({ value: country.code, label: country.label, icon: <Icon mr={3} as={CircleFlag} countryCode={country.code.toLowerCase()} width={'20px'} /> }));
  const defaultCountry = mappedCountries.filter((country) => country.value == (defaultValue || 'BA'))[0]

  return (
    <Controller
      control={control}
      defaultValue={defaultCountry}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <Select
          options={mappedCountries}
          chakraStyles={{
            menu: (provided, state) => ({
              ...provided,
              zIndex: 999
            })
          }}
          placeholder="Odaberite opciju..."
          closeMenuOnSelect={true}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          components={iconComponents}
          noOptionsMessage={() => 'Nema dostupnih opcija.'}
          selectedOptionStyle="check"
        />
      )} />
  );
};

export default SupportedCountrySelect;