import { AlertDialog, Text, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, Spacer, HStack, Button, AlertDialogFooter, useDisclosure } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { IRequest, IRequestOffer } from "../../../resources/interfaces";
import { useCloseRequestMutation, useGetRequestQuery } from '../../../state/services/requests';


interface IProps {
  disclosure: any;
  request: IRequest;
  selectedRequestOffer?: IRequestOffer;
};

const CloseRequest = ({ request, disclosure, selectedRequestOffer = null }: IProps) => {
  const { register, control, handleSubmit, getValues, watch, resetField, setValue } = useForm();

  const [closeRequest] = useCloseRequestMutation();

  const { isOpen, onOpen, onClose } = disclosure;

  const cancelRef = React.useRef();

  watch('request_offer_id');

  const handleCloseRequest = (data) => {
    closeRequest({
      id: parseInt(request.id.toString()),
      request_offer_id: data?.request_offer_id?.value
    });

    onClose();
  };

  useEffect(() => {
    resetField('request_offer_id');
    setValue('request_offer_id', selectedRequestOffer == null ? null : { value: selectedRequestOffer?.id, label: `${selectedRequestOffer?.company?.name} (${selectedRequestOffer?.amount})` });
  }, [isOpen])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <form onSubmit={handleSubmit(handleCloseRequest)}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Zatvori zahtjev
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Da li ste sigurni da želite zatvoriti zahtjev?</Text>
              <Spacer h={'20px'} />
              {request?.offers?.length > 0 &&
                <>
                  <Controller
                    control={control}
                    name="request_offer_id"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        options={request?.offers?.map((offer) => ({ value: offer.id, label: `${offer.company.name} (${offer.amount})` }))}
                        placeholder="Odaberite prijevoznika..."
                        closeMenuOnSelect={true}
                        selectedOptionStyle="check"
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )} />
                  <Spacer h={'20px'} />
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Odustani
              </Button>
              <Button colorScheme='red' onClick={handleSubmit(handleCloseRequest)} ml={3}>
                Zatvori
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default CloseRequest;