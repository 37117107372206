const countries = [
  {
    "code": "AD",
    "label": "Andora"
  },
  {
    "code": "AL",
    "label": "Albanija"
  },
  {
    "code": "AM",
    "label": "Armenija"
  },
  {
    "code": "AT",
    "label": "Austrija"
  },
  {
    "code": "AZ",
    "label": "Azerbejdžan"
  },
  {
    "code": "BA",
    "label": "Bosna i Hercegovina"
  },
  {
    "code": "BE",
    "label": "Belgija"
  },
  {
    "code": "BG",
    "label": "Bugarska"
  },
  {
    "code": "BY",
    "label": "Bjelorusija"
  },
  {
    "code": "CH",
    "label": "Švicarska"
  },
  {
    "code": "CY",
    "label": "Kipar"
  },
  {
    "code": "CZ",
    "label": "Češka"
  },
  {
    "code": "DE",
    "label": "Njemačka"
  },
  {
    "code": "DK",
    "label": "Danska"
  },
  {
    "code": "EE",
    "label": "Estonija"
  },
  {
    "code": "ES",
    "label": "Španija"
  },
  {
    "code": "FI",
    "label": "Finska"
  },
  {
    "code": "FR",
    "label": "Francuska"
  },
  {
    "code": "GB",
    "label": "Velika Britanija"
  },
  {
    "code": "GE",
    "label": "Gruzija"
  },
  {
    "code": "GR",
    "label": "Grčka"
  },
  {
    "code": "HR",
    "label": "Hrvatska"
  },
  {
    "code": "HU",
    "label": "Mađarska"
  },
  {
    "code": "IE",
    "label": "Irska"
  },
  {
    "code": "IS",
    "label": "Island"
  },
  {
    "code": "IT",
    "label": "Italija"
  },
  {
    "code": "JE",
    "label": "Džerzi"
  },
  {
    "code": "KZ",
    "label": "Kazahstan"
  },
  {
    "code": "LI",
    "label": "Lihtenštajn"
  },
  {
    "code": "LT",
    "label": "Litvanija"
  },
  {
    "code": "LU",
    "label": "Luksemburg"
  },
  {
    "code": "LV",
    "label": "Latvija"
  },
  {
    "code": "MC",
    "label": "Monako"
  },
  {
    "code": "MD",
    "label": "Moldavija"
  },
  {
    "code": "ME",
    "label": "Crna Gora"
  },
  {
    "code": "MK",
    "label": "Sjeverna Makedonija"
  },
  {
    "code": "MT",
    "label": "Malta"
  },
  {
    "code": "NL",
    "label": "Holandija"
  },
  {
    "code": "NO",
    "label": "Norveška"
  },
  {
    "code": "PL",
    "label": "Poljska"
  },
  {
    "code": "PT",
    "label": "Portugal"
  },
  {
    "code": "RO",
    "label": "Rumunija"
  },
  {
    "code": "RS",
    "label": "Srbija"
  },
  {
    "code": "RU",
    "label": "Rusija"
  },
  {
    "code": "SE",
    "label": "Švedska"
  },
  {
    "code": "SI",
    "label": "Slovenija"
  },
  {
    "code": "SJ",
    "label": "Svalbard i Jan Majen"
  },
  {
    "code": "SK",
    "label": "Slovačka"
  },
  {
    "code": "SM",
    "label": "San Marino"
  },
  {
    "code": "TR",
    "label": "Turska"
  },
  {
    "code": "UA",
    "label": "Ukrajina"
  },
  {
    "code": "XK",
    "label": "Kosovo"
  }
]

export const findByCode = (code: string) => {
  return countries.find((country) => country.code == (code?.toUpperCase() || 'BA'));
};

export default countries;