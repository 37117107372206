import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Image,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Container,
  Text,
  Icon,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  usePopoverContext,
  Spacer,
  Input,
  Badge,
  AvatarBadge,
  Show,
  Hide,
  VStack,
} from '@chakra-ui/react';
import { FaHamburger, FaTimesCircle, FaPlusCircle, FaHome, FaChevronDown, FaBell } from 'react-icons/fa';
import React from 'react';
import { FiArrowRightCircle, FiAward, FiBox, FiCheckCircle, FiCheckSquare, FiChevronDown, FiDisc, FiExternalLink, FiFilePlus, FiFlag, FiHome, FiMenu, FiPackage, FiPhone, FiPlusCircle, FiPrinter, FiRefreshCcw, FiSettings, FiShield, FiTruck, FiUser } from 'react-icons/fi';
import { GrMoney } from 'react-icons/gr';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { MdOutlineSwapHorizontalCircle, MdOutlineCheckCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import NavigationItem from './ Navigation/NavigationItem';
import { logoutSession } from '../../../helpers/sessionHelper';
import { TBusinessType } from '../../../resources/interfaces';

const Links = ['Početna', 'Zaduženja', 'Razduženja'];


const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    color={'white'}
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

export default function Navigation({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const logout = () => {
    logoutSession(dispatch)
  };

  const notificationsUrl = currentUser?.company?.businessType == TBusinessType.Standard ? '/notifications' : '/app/notifications'

  return (
    <>
      <Box py={5} boxShadow={'lg'} color={'white'} borderBottom={'solid 1px lightgrey'} bg={'#0E0E1A'} px={4}>
        <Container maxW={'container.xl'} py={5}>
          <HStack justifyContent={'space-between'}>
            <Image w={'256px'} src={'/images/logo-white.png'} />
            <Show above={'md'}>
              <Button as={ReachLink} to={notificationsUrl} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<Icon as={FaBell} />} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>
                <Text>Obavijesti</Text>
                {currentUser?.company?.notificationsCount > 0 &&
                  <Badge px={'4px'} colorScheme={'red'} bg={'red.500'} color={'white'} borderRadius={'50%'} ml={3}>{currentUser?.company?.notificationsCount}</Badge>}
              </Button>
            </Show>
            <Hide above={'md'}>
              <HStack spacing={-3}>
                <IconButton as={ReachLink} to={notificationsUrl} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} borderRadius={'50%'} bg={'gray.700'} aria-label={''} icon={<Icon as={FaBell} />} colorScheme={'blackAlpha'}>
                </IconButton>
                {currentUser?.company?.notificationsCount > 0 &&
                  <Badge position={'relative'} zIndex={100} mt={'30px'} px={'5px'} colorScheme={'red'} bg={'red.500'} color={'white'} borderRadius={'50%'} ml={3}>{currentUser?.company?.notificationsCount}</Badge>
                }
              </HStack>
            </Hide>

          </HStack>
          <Spacer h={'20px'} />
          <HStack>
            <Text fontWeight={300} fontSize={'24px'}>Dobrodošli na platformu, {currentUser?.company?.name}.</Text>
            <Link onClick={logout} fontSize={'sm'}>(Odjava)</Link>
          </HStack>
          <Spacer h={'20px'} />

          <Stack direction={['column', 'row', 'row', 'row']}>
            {currentUser?.company?.businessType == TBusinessType.Standard && <>
              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  Roba i teret
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/app'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPackage} />} bg={'#3e3e48'} p={5}>Sve ponude robe i tereta</MenuItem>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/dashboard'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiUser} />} bg={'#3e3e48'} p={5}>Moje ponude robe i tereta</MenuItem>
                  <MenuItem as={ReachLink} to={'/create-request'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>Dodaj novu ponudu robe ili tereta</MenuItem>
                </MenuList>
              </Menu>
              <Button as={ReachLink} to={'/create-request'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPlusCircle />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>Dodajte novu ponudu robe/tereta</Button>
              <Button as={ReachLink} to={'/offers'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiTruck />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>Dostupna vozila</Button>
            </>}

            {currentUser?.company?.businessType == TBusinessType.Transportation && <>
              <Button as={ReachLink} to={'/app'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>Ponude robe/tereta</Button>
              <Button as={ReachLink} to={'/app/offers'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiFlag />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>Moja slobodna vozila</Button>
              <Button as={ReachLink} to={'/app/create-offer'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPlusCircle />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>Dodaj novo slobodno vozilo</Button>
            </>}

            {currentUser?.company?.businessType == TBusinessType.Forwarder && <>

              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  Roba i teret
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/app'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPackage} />} bg={'#3e3e48'} p={5}>Sve ponude robe i tereta</MenuItem>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/dashboard'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiUser} />} bg={'#3e3e48'} p={5}>Moje ponude robe i tereta</MenuItem>
                  <MenuItem as={ReachLink} to={'/create-request'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>Dodaj novu ponudu robe ili tereta</MenuItem>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiTruck />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  Vozila
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/offers'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiTruck} />} bg={'#3e3e48'} p={5}>Slobodna vozila</MenuItem>
                  <MenuItem as={ReachLink} to={'/app/create-offer'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>Dodaj novo slobodno vozilo</MenuItem>
                </MenuList>
              </Menu>

            </>}

            {currentUser?.isAdmin && <>
              <Button as={ReachLink} to={'/admin'} fontSize={'md'} leftIcon={<FiShield />} iconSpacing={5} size={'lg'} colorScheme={'red'}>Administracija</Button>
            </>}

            <Button as={Link} href={'https://cargo.ba/obuka-za-transportne-speditere/'} target={'_blank'} fontSize={'md'} rightIcon={<FiExternalLink />} leftIcon={<FiAward />} iconSpacing={5} size={'lg'} colorScheme={'orange'}>
              <VStack spacing={0} alignItems={'flex-start'}>
                <Text>Obuka za transportne špeditere</Text>
                <Text fontSize={'xs'}>Cargo.ba</Text>
              </VStack>

            </Button>
          </Stack>
        </Container>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Container maxW={'container.xl'}>
        {children}
      </Container>
    </>
  );
}