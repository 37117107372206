import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Spinner, Center, Skeleton, SkeletonCircle, SkeletonText, Show, useDisclosure, AvatarBadge, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiStar, FiThumbsDown, FiThumbsUp, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TBusinessType, TDepartmentTechnicalName, TOfferState, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowLeft, FaArrowRight, FaCircle, FaFire, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useGetTransportationRequestQuery } from '../../state/services/transportation/requests';
import { CircleFlag } from 'react-circle-flags';
import { useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import CloseRequest from '../StandardCompany/Request/CloseRequest';
import { useGetTransportationOfferQuery } from '../../state/services/transportation/offers';
import OfferInformation from '../Offer/OfferInformation';
import { useGetOfferQuery } from '../../state/services/offers';


const Offer = () => {
  let { id } = useParams();

  const closeDisclosure = useDisclosure();

  const { data, isLoading, isSuccess } = useGetOfferQuery(parseInt(id));

  const offer = data?.offer;

  return (
    <>
      <Spacer h={'20px'} id={'view-placement'} />
      {isLoading && <>
        <Center>
          <Spinner />
        </Center>
      </>}

      {
        isSuccess && <>
          <HStack justifyContent={'space-between'} id={'view-placement'}>
            <Button size={'sm'} as={ReachLink} to={'/offers'} leftIcon={<FaArrowLeft />}>Nazad</Button>
          </HStack>
          <Spacer h={'20px'} />

          <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
            <Stack direction={['column', 'row', 'row', 'row']} justifyContent={'space-between'} alignItems={['center']} spacing={[5, 0]}>
              <Box>
                <HStack spacing={5}>
                  <Avatar size={'md'} name={offer?.company?.name} src={offer?.company?.logoPath}>
                    <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${offer?.company?.rating} od 5 na osnovu prethodni vožnji.`}>
                      <AvatarBadge color={'black'} boxSize='2.5em' bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{offer?.company?.rating}</AvatarBadge>
                    </Tooltip>
                  </Avatar>
                  <VStack spacing={0} alignItems={'left'}>
                    <Text fontSize={'md'}>{offer?.company?.name}</Text>
                    <Text color={'gray'} fontSize={'sm'}>prije {offer?.createdAtBefore}.</Text>
                  </VStack>
                </HStack>
              </Box>
              <Box>
                <Text fontSize={'xs'}>Kontakt telefon</Text>
                <Text fontWeight={'bold'}>{offer?.company?.phone}</Text>
              </Box>
              <Box>
                <HStack>
                  <Tooltip label={'Pozovite prevoznika na telefon'} bg={'green'} color={'white'} hasArrow>
                    <Button borderRadius={'full'} as={Link} href={`tel:${offer?.company?.phone}`} colorScheme={'green'} leftIcon={<Icon as={FaPhone} mr={3} />} aria-label={"Kontakt telefon"}>Pozovite</Button>
                  </Tooltip>

                  <Tooltip label={'Pozovite prevoznika na Viber'} bg={'purple'} color={'white'} hasArrow>
                    <Button borderRadius={'full'} ml={4} as={Link} href={`viber://chat?number=${offer?.company?.phone}`} colorScheme={'purple'} leftIcon={<Icon as={FaViber} mr={3} />} aria-label={"Poziv na viber"}>Poziv na Viber</Button>
                  </Tooltip>
                </HStack>
              </Box>
            </Stack>
          </Box>

          <OfferInformation offer={offer} />
        </>
      }

      <Spacer h={'150px'} />
    </>
  )
};

export default Offer;