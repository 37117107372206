import { HStack, Icon, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import countries from '../../resources/eu_countries';
import { Controller } from "react-hook-form";
import { iconComponents } from "../../resources/icon_select";

const CountrySelect = ({ control, name, defaultValue = null, handleOnChange = (e) => { } }) => {
  const mappedCountries = countries.map((country) => ({ value: country.code, label: country.label, icon: <Icon mr={3} as={CircleFlag} countryCode={country.code.toLowerCase()} width={'20px'} /> }));
  const defaultCountry = mappedCountries.filter((country) => country.value == (defaultValue))[0]

  return (
    <Controller
      control={control}
      defaultValue={defaultCountry}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <Select
          options={mappedCountries}
          placeholder="Odaberite državu..."
          closeMenuOnSelect={true}
          aria-label={'Država'}
          ref={ref}
          onChange={(e) => { onChange(e); handleOnChange(e); }}
          onBlur={onBlur}
          value={value}
          components={iconComponents}
          noOptionsMessage={() => 'Nema dostupnih opcija.'}
          selectedOptionStyle="check"
        />
      )} />
  );
};

export default CountrySelect;