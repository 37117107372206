import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Spinner, Center, Skeleton, SkeletonCircle, SkeletonText, Show, useDisclosure, AvatarBadge, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, Hide } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiStar, FiThumbsDown, FiThumbsUp, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IOffer, IRequest, TBusinessType, TDepartmentTechnicalName, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowLeft, FaArrowRight, FaCircle, FaEllipsisV, FaFire, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useCloseRequestMutation, useGetRequestQuery } from '../../state/services/requests';
import { CircleFlag } from 'react-circle-flags';
import { useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import CloseRequest from '../StandardCompany/Request/CloseRequest';
import CreateRequestOffer from '../TransportationCompany/Request/CreateRequestOffer';
import TransportationRequestOffer from '../TransportationCompany/Request/RequestOffer';
import RequestOffers from '../StandardCompany/Request/RequestOffers';

interface IProps {
  offer: IOffer;
}

const OfferInformation = ({ offer }: IProps) => {
  return (
    <>

      <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
        <Stack direction={['column', 'row', 'row', 'row']} spacing={[10, 0]} justifyContent={'space-between'}>
          <Box w={'full'}>
            <Box>
              <Stack flexWrap={'wrap'} alignItems={'center'} direction={'row'} justifyContent={'center'}>
                {offer.vehicles.map((vehicle) => (
                  <Tooltip label={vehicle.name} hasArrow placement={'top'}>
                    <Image src={vehicle.imagePath} w={'94px'} h={'100%'} />
                  </Tooltip>
                ))}
              </Stack>
              <Flex p={5} gap={5} flexWrap={'wrap'} justifyContent={'center'}>

                {offer.isAdr &&
                  <Tag size={'sm'} variant='subtle' colorScheme='red'>
                    <TagLeftIcon boxSize='12px' as={FaFire} />
                    <TagLabel fontWeight={'bold'}>Opasna roba (ADR)</TagLabel>
                  </Tag>}

                {offer.isFrigo &&
                  <Tag size={'sm'} variant='subtle' colorScheme='blue'>
                    <TagLeftIcon boxSize='12px' as={FaSnowflake} />
                    <TagLabel fontWeight={'bold'}>FRIGO</TagLabel>
                  </Tag>}

                {offer.isRamp &&
                  <Tag size={'sm'} variant='subtle' colorScheme='green'>
                    <TagLeftIcon boxSize='12px' as={FaTruckLoading} />
                    <TagLabel fontWeight={'bold'}>Dostupna rampa</TagLabel>
                  </Tag>}
              </Flex>
            </Box>
            <Stack direction={['column', 'row']} mt={5} justifyContent={'space-evenly'} alignItems={['flex-start', 'center']} spacing={[0, '30px']}>
              <Box>
                <Stack direction={['row', 'column']} spacing={[5, 0]}>
                  <CircleFlag countryCode={offer.loadingCountry.toLowerCase()} width={'24px'} />
                  <Box m={0}>
                    <Text fontSize={'md'} mt={2} fontWeight={'bold'}>{offer.loadingCity}</Text>
                    <Text fontSize={'sm'} color={'gray'}>{offer.loadingAddress}</Text>
                  </Box>
                </Stack>
              </Box>
              <Show above={'sm'}>
                <Icon as={FaArrowRight} />
              </Show>
              <Hide above={'sm'}>
                <Icon as={FaEllipsisV} />
              </Hide>
              <Box>
                <Stack direction={['row', 'column']} spacing={[5, 0]}>
                  <CircleFlag countryCode={offer.unloadingCountry.toLowerCase()} width={'24px'} />
                  <Box m={0}>
                    <Text fontSize={'md'} mt={2} fontWeight={'bold'}>{offer.unloadingCity}</Text>
                    <Text fontSize={'sm'} color={'gray'}>{offer.unloadingAddress}</Text>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {offer.note &&
              <>
                <Divider my={3} />
                <Box p={3}>
                  <Text fontSize={'xs'} fontWeight={'bold'}>NAPOMENA:</Text>
                  {offer.note}
                </Box>
              </>
            }
          </Box>
          <Table w={'full'} size={'sm'} mt={5} variant={'striped'}>
            <Tbody>
              <Tr>
                <Td>Datum preuzimanja</Td>
                <Td>{displayDate(offer.loadingDate)}</Td>
              </Tr>
              <Tr>
                <Td>Broj paleta</Td>
                <Td>{offer.pallets}</Td>
              </Tr>
              <Tr>
                <Td>Broj komada</Td>
                <Td>{offer.packages}</Td>
              </Tr>
              <Tr>
                <Td>Slobodan prostor (m³)</Td>
                <Td>{offer.space} m³</Td>
              </Tr>
              <Tr>
                <Td>Opcija otpreme robe</Td>
                <Td>
                  {offer.loadingType == TRequestLoadingType.FTL && 'FTL - Puni utovar'}
                  {offer.loadingType == TRequestLoadingType.LTL && 'LTL - Zbirni transport'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Stack>
      </Box>
    </>
  )
};

export default OfferInformation;