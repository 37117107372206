import { AlertDialog, Text, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Spacer, InputGroup, Input, InputRightAddon, AlertDialogFooter, Button, useToast, Flex, Box, HStack, Icon, VStack, useDisclosure, Avatar, AvatarBadge, Center, IconButton, Link, Stack, Tooltip, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPhone, FaStar, FaTimesCircle, FaTruck, FaViber } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { useRateRequestOfferMutation } from "../../../state/services/request_offers";
import { IRequest, IRequestOffer, TRequestState } from "../../../resources/interfaces";
import CreateRequestOffer from "../../TransportationCompany/Request/CreateRequestOffer";

interface IProps {
  request: IRequest;
  closeRequest: (requestOffer: IRequestOffer) => void;
};

const RequestOffers = ({ request, closeRequest }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [rating, setRating] = React.useState(5);
  const toast = useToast();

  const [rateRequestOffer, { isSuccess }] = useRateRequestOfferMutation();

  const onRate = () => {
    rateRequestOffer({
      requestId: request.id,
      id: request?.offers?.find((e) => e.accepted)?.id,
      rating: rating
    });
    onClose();
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Uspješno!',
        description: "Uspješno ste ocijenili prijevoznika.",
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isSuccess]);

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ocijeni prijevoznika</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Ocijenite prijevoznika odabirom od 1 do 5:</Text>

            <Spacer h={'20px'} />
            <HStack>
              {[1, 2, 3, 4, 5].map((n) => {
                return (
                  <Button onClick={() => setRating(n)} bg={rating == n ? 'black' : 'white'} border={'solid 1px'} borderColor={'gray.300'} _hover={{ bg: 'black', color: 'white' }} borderRadius={'50%'} px={'14px'} py={'5px'} fontSize={'lg'} color={rating == n ? 'white' : 'gray.500'} fontWeight={'bold'}>{n}</Button>
                )
              })}
            </HStack>
            <Spacer h={'20px'} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='orange' mr={3} onClick={onRate}>
              Ocijeni
            </Button>
            <Button onClick={onClose} variant='ghost'>Odustani</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {request?.offers.length == 0 && <>
        <Spacer h={'20px'} />
        <Box>
          <Center mt={3}>
            <HStack spacing={5}>
              <Icon as={FaTruck} />
              <Text>Još uvijek nema ponuda na ovaj zahtjev.</Text>
            </HStack>
          </Center>
        </Box>
      </>}

      {request?.offers?.map((offer) => (
        <>
          <Box onClick={() => request.state == TRequestState.Created ? closeRequest(offer) : null} border={offer.accepted ? 'solid 1px gold' : ''} _hover={{ boxShadow: 'md' }} cursor={'pointer'} w={'full'} mb={5} bg={offer.accepted ? 'orange.50' : 'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
            <Stack direction={['column', 'row', 'row', 'row']} spacing={5} justifyContent={'space-between'}>
              <HStack w={'full'} spacing={5}>
                <Avatar name={offer.company.name} src={offer.company.logoPath}>
                  <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${offer?.company?.rating} od 5 na osnovu prethodni vožnji.`}>
                    <AvatarBadge boxSize='2.5em' color={'black'} bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{offer?.company?.rating}</AvatarBadge>
                  </Tooltip>
                </Avatar>
                <VStack alignItems={'flex-start'}>
                  <HStack>
                    <Text fontSize={'16px'} fontWeight={400}>{offer.company.name}</Text>
                    <Text fontSize={'12px'} fontWeight={400} color={'gray'}>prije {offer.createdAtBefore}</Text>
                  </HStack>
                  <Text fontSize={'14px'} color={'gray'}>{offer.company.phone}</Text>
                </VStack>
              </HStack>
              <Stack alignItems={'center'} w={'full'} direction={'row'} justifyContent={'space-between'}>
                <Box>
                  <Text fontSize={'18px'}>{offer.amount}</Text>
                </Box>

                <Stack direction={'row'}>
                  {offer.accepted && offer?.rating == null && <>
                    <Button onClick={(e) => { handleLinkClick(e); onOpen() }} leftIcon={<FaStar />} colorScheme={'orange'}>Ocijeni prijevoznika</Button>
                  </>}
                  {offer.accepted && offer?.rating != null && <>
                    <Text>Vaša ocjena: {offer.rating}</Text>
                  </>}
                  {!offer.accepted && <>
                    <Tooltip label={'Pozovite prevoznika na telefon'} bg={'green'} color={'white'} hasArrow>
                      <IconButton onClick={handleLinkClick} borderRadius={'full'} as={Link} href={`tel:${offer?.company?.phone}`} colorScheme={'green'} icon={<FaPhone />} aria-label={"Poziv na telefon"}>Poziv</IconButton>
                    </Tooltip>

                    <Tooltip label={'Pozovite prevoznika na Viber'} bg={'purple'} color={'white'} hasArrow>
                      <IconButton onClick={handleLinkClick} borderRadius={'full'} ml={4} as={Link} href={`viber://chat?number=${offer?.company?.phone}`} colorScheme={'purple'} icon={<FaViber />} aria-label={"Poziv na viber"}>Viber</IconButton>
                    </Tooltip>
                  </>}
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </>
      ))
      }
    </>
  );
}

export default RequestOffers;