import { Box, HStack, Icon, Spacer, Text, Button, Stack, Input, InputGroup, InputRightAddon, Textarea, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, useToast, Spinner, Center, Show, InputLeftAddon, Tooltip, Checkbox, Alert } from '@chakra-ui/react'
import * as React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaCheckCircle, FaFire, FaPhone, FaQuestionCircle, FaSnowflake, FaTruckLoading } from 'react-icons/fa';
import { GiShatteredGlass } from 'react-icons/gi';
import { useNavigate, Link as ReachLink } from 'react-router-dom';
import {
  Select,
} from "chakra-react-select";
import { useGetVehiclesQuery } from '../../state/services/vehicles';
import Vehicle from './CreateRequest/Vehicle';
import { Controller, useForm } from 'react-hook-form';
import Service from './CreateRequest/Service';
import CountrySelect from '../../components/Form/CountrySelect';
import { useCreateRequestMutation } from '../../state/services/requests';
import { useEffect } from 'react';
import { todayDate, todayDatePlus } from '../../helpers/dateHelper';

const CreateRequest = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);

  const { register, handleSubmit, control, getValues, setValue, watch, formState: { errors } } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef()
  const toast = useToast();
  const navigate = useNavigate();

  const loadingDateW = watch('loading_date');

  const { data: vehiclesData } = useGetVehiclesQuery();
  const [createRequest, { isLoading, isSuccess, isError }] = useCreateRequestMutation();

  const getData = () => {
    const data = getValues();

    data.vehicles = Object.keys(data.vehicles).filter((key) => data.vehicles[key])?.map((k) => k.replace('vehicle_id_', ''));
    data.loading_country = data.loading_country.value;
    data.unloading_country = data.unloading_country.value;
    data.loading_type = data.loading_type.value;

    return data;
  }
  const onSubmit = () => {
    if (getData().vehicles.length <= 0) {
      toast({
        title: 'Greška!',
        description: "Molimo odaberite bar jedno vozilo.",
        status: 'error',
        duration: 5000,
        position: 'bottom',
        isClosable: true,
      })

      return;
    }

    onOpen();
  }

  const onConfirm = () => {
    createRequest(getData()).then((data) => {
      const id = data?.data?.request?.id;

      if (id) {
        navigate(`/requests/${id}`)
      }
    });
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Greška!',
        description: "Dogodila se greška prilikom izrade zahtjeva. Molimo pokušajte ponovo.",
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isError]);

  useEffect(() => {
    setValue('unloading_date', loadingDateW);
  }, [loadingDateW]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            {!isLoading &&
              <>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Potvrda!
                </AlertDialogHeader>

                <AlertDialogBody>
                  Da li ste sigurni da želite napraviti novi zahtjev?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Odustani
                  </Button>
                  <Button leftIcon={<Icon as={FaCheckCircle} />} onClick={onConfirm} colorScheme='green' ml={3}>
                    Nastavi
                  </Button>
                </AlertDialogFooter>
              </>}

            {isLoading && <>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Molimo pričekajte... Zahtjev je u izradi.
              </AlertDialogHeader>
              <AlertDialogBody>
                <Center>
                  <Spinner />
                </Center>

                <Spacer h={'20px'} />
              </AlertDialogBody>
            </>}

          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Spacer h={'20px'} />
      <Stack direction={['column', 'row']} justifyContent={'space-between'}>
        <Button size={'sm'} as={ReachLink} to={'/dashboard'} leftIcon={<FaArrowLeft />}>Nazad</Button>
        <Button onClick={handleSubmit(onSubmit)} size={'sm'} as={ReachLink} to={'/dashboard'} colorScheme={'green'} leftIcon={<FaCheckCircle />}>Napravi zahtjev</Button>
      </Stack>
      <Spacer h={'20px'} />
      <form onSubmit={handleSubmit(onSubmit)}>

        <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
          <HStack spacing={0} justifyContent={'space-between'} flexDirection={['column', 'row']} alignItems={['center', 'flex-end']}>
            <Box>
              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Preuzimanje</Text>
              <CountrySelect control={control} defaultValue={'BA'} name={'loading_country'} />
              <Spacer h={'10px'} />
              <HStack>
                <Input isInvalid={errors?.loading_city} textTransform={'capitalize'} {...register('loading_city', { required: true })} defaultValue={currentUser?.company?.city} placeholder={'Grad'}></Input>
                <Input isInvalid={errors?.loading_address} textTransform={'capitalize'} {...register('loading_address')} defaultValue={currentUser?.company?.address} placeholder={'Adresa'}></Input>
              </HStack>

              <InputGroup mt={2}>
                <InputLeftAddon children={<Icon as={FaPhone} />} />
                <Input isInvalid={errors?.loading_phone} type={'number'} {...register('loading_phone')} placeholder={'Kontakt telefon za preuzimanje'} />
              </InputGroup>
            </Box>

            <Box pt={[3, 0]}>
              <Show above={'md'}>
                <Icon fontSize={'20px'} as={FaArrowRight} mb={'5px'} />
              </Show>
              <Show below={'md'}>
                <Icon fontSize={'20px'} as={FaArrowDown} mb={'5px'} />
              </Show>
            </Box>

            <Box>
              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dostava</Text>

              <CountrySelect control={control} defaultValue={'BA'} name={'unloading_country'} />
              <Spacer h={'10px'} />
              <HStack>
                <Input isInvalid={errors?.unloading_city} textTransform={'capitalize'} {...register('unloading_city', { required: true })} placeholder={'Grad'}></Input>
                <Input isInvalid={errors?.unloading_address} textTransform={'capitalize'} {...register('unloading_address')} placeholder={'Adresa'}></Input>
              </HStack>

              <InputGroup mt={2}>
                <InputLeftAddon children={<Icon as={FaPhone} />} />
                <Input isInvalid={errors?.unloading_phone} type={'number'} {...register('unloading_phone')} placeholder={'Kontakt telefon za dostavu'} />
              </InputGroup>
            </Box>
          </HStack>
        </Box>

        <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
          <HStack flexDirection={['column', 'row']} alignItems={'flex-start'} spacing={[0, 10]}>
            <Box w={'full'}>
              <Box mb={5} >
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Vrsta robe/tereta</Text>
                <Input {...register('goods_description', { required: true, maxLength: 250 })} placeholder={'Unesite opis robe/tereta...'}></Input>
              </Box>

              <Box mb={5} >
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Opcija utovara</Text>
                <Controller
                  control={control}
                  name="loading_type"
                  defaultValue={{ value: 'ftl', label: 'FTL - puni utovar' }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error }
                  }) => (
                    <Select
                      options={[
                        { value: 'ftl', label: 'FTL - puni utovar' },
                        { value: 'ltl', label: 'LTL - zbirni transport' },
                      ]}
                      placeholder="Odaberite opciju..."
                      closeMenuOnSelect={true}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      selectedOptionStyle="check"
                    />
                  )} />
              </Box>

              <Box mb={5}>
                <Stack direction={['column', 'row']}>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum preuzimanja</Text>
                    <Input {...register('loading_date', { required: true })} min={todayDate()} type={'date'} defaultValue={todayDate()}></Input>
                  </Box>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum dostave</Text>
                    <Input {...register('unloading_date', { required: true })} min={getValues('loading_date')} type={'date'} defaultValue={todayDatePlus(5)}></Input>
                  </Box>
                </Stack>
              </Box>

              <Box mb={5}>
                <Stack direction={['column', 'row']}>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Masa tereta</Text>
                    <InputGroup>
                      <Input isInvalid={errors?.weight} type={'number'} {...register('weight', { required: true })} />
                      <InputRightAddon children='kg' />
                    </InputGroup>
                  </Box>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dimenzije (Dužina x Širina x Visina)</Text>
                    <InputGroup>
                      <Input placeholder={'D'} {...register('dimensions_length')} type={'number'} />
                      <Input placeholder={'Š'} {...register('dimensions_width')} type={'number'} borderLeft={0} borderLeftRadius={0} />
                      <Input placeholder={'V'} {...register('dimensions_height')} type={'number'} borderLeft={0} borderLeftRadius={0} />
                      <InputRightAddon children='cm' />
                    </InputGroup>
                  </Box>
                </Stack>
              </Box>

              <Box mb={5}>
                <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Prostor (m³)</Text>
                <InputGroup>
                  <Input {...register('space')} />
                  <InputRightAddon children='m³' />
                </InputGroup>
              </Box>

              <Box mb={5}>
                <HStack>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj paleta</Text>
                    <InputGroup>
                      <Input type={'number'} {...register('pallets')} />
                      {/*<ChakraSelect bg={'gray.100'} borderLeftRadius={0} borderLeft={0}>
                        <option value={'euro'}>Euro</option>
                    </ChakraSelect>*/}
                    </InputGroup>
                  </Box>
                  <Box w={'full'}>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj komada</Text>
                    <Input type={'number'} {...register('packages')} />
                  </Box>
                </HStack>
              </Box>

              <Service technicalName={'is_ramp'} control={control} name={'Potrebna rampa'} description={'Označite ovu opciju ukoliko vam je potrebna rampa na vozilu.'} color={'green'} icon={FaTruckLoading} />
              <Service technicalName={'is_adr'} control={control} name={'ADR - Opasna roba'} description={'Označite ovu opciju ukoliko je vaš teret ili roba opasna.'} color={'red'} icon={FaFire} />
              <Service technicalName={'is_frigo'} control={control} name={'FRIGO'} description={'Označite ovu opciju ukoliko vam je potrebno vozilo sa hladnjačama.'} color={'blue'} icon={FaSnowflake} />
              <Service technicalName={'is_fragile'} control={control} name={'Lomljivo'} description={'Označite ovu opciju ukoliko su roba ili teret lomljivi.'} color={'orange'} icon={GiShatteredGlass} />
            </Box>
            <Box w={'full'}>

              <Alert colorScheme={'gray'} borderRadius={'15px'}>
                <Checkbox {...register('show_info')} defaultChecked={true}>
                  <Tooltip hasArrow label={'Ukoliko želite prikazati podatke Vaše firme poput naziva, adrese i broja telefona na ponudi tereta/robe označite ovu opciju.'}>
                    <Text cursor={'pointer'}>
                      Prikaži podatke naše firme na ponudi.
                      <Icon ml={3} as={FaQuestionCircle} />
                    </Text>
                  </Tooltip>
                </Checkbox>
              </Alert>



              <Spacer h={'25px'} />

              <Textarea {...register('note')} placeholder={'Dodatna napomena...'}></Textarea>

              <Spacer h={'25px'} />

              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Potrebna vozila</Text>

              {vehiclesData?.vehicles?.map((vehicle) => (
                <Vehicle id={vehicle.id} control={control} controlName={'vehicle'} key={vehicle.id} vehicle={vehicle} />
              ))}
            </Box>
          </HStack>
        </Box>
      </form>

      <Spacer h={'30px'} />

      {/* <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
        <iframe
          width={'100%'}
          height="250"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/directions?&origin=Sarajevo
              &destination=Tuzla&key=AIzaSyC8woYBVNCZEtNc5snPC_7OtHXVb8AVoGI">
        </iframe>
  </Box>*/}

    </>
  )
};

export default CreateRequest;