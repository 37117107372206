import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';

const Privacy = () => {
  return (
    <>

      <Box>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>Nazad</Button>


            <Spacer h={'30px'} />

            <Text fontSize={'xl'} fontWeight={'light'}>Politika privatnosti</Text>

            <Divider my={5} />

            <Text>
              Poštovani korisnici, naša platforma za berzu transporta vrlo ozbiljno shvata svoju obavezu čuvanja privatnosti naših korisnika i posjetitelja. Ova Politika privatnosti objašnjava kako prikupljamo, obrađujemo, koristimo i dijelimo vaše osobne podatke. Molimo vas da pažljivo pročitate ovu Politiku privatnosti prije korištenja naših usluga i platforme.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Prikupljanje osobnih podataka
            </Heading>
            <Text>
              Prilikom prijave ili registracije na našu platformu, tražit ćemo od vas da nam pružite određene osobne podatke, uključujući ali ne ograničavajući se na vaše ime, naziv Vaše kompanije, vašu adresu, adresu kompanije, adresu e-pošte, broj mobitela i druge informacije kako bismo Vam pružili članske pogodnosti i obavijestili Vas o našim uslugama i drugim pogodnostima ili mogućnostima povezanim sa Vašim članstvom na našoj platformi.
            </Text>
            <Text mt={2}>
              Osim toga, prikupljamo informacije o vašoj upotrebi naše platforme, uključujući ali ne ograničavajući se na podatke s vašeg računara, što može uključivati IP adresu, a postoje i situacije u kojima automatski prikupljamo druge vrste podataka kao što su datum i vrijeme pristupa platformi, informacije o hardveru, softveru ili internet pretraživaču koji koristite kao i o operativnom sistemu vašeg računara, te verziji aplikacije i vašim jezičkim postavkama, kako bismo Vam pružili najbolju moguću uslugu i iskustvo.
            </Text>

            <Text mt={2}>
              Naša platforma upotrebljava neosjetljive podatke poput kolačića (engl. cookies) ili identifikatora uređaja u svrhe kao što su mjerenje/statistika posjeta i pregleda sadržaja (Google Analytics), pamćenja Vaše prijave, što omogućava bolje korisničko iskustvo i ispravan prikaz sadržaja.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Upotreba osobnih podataka
            </Heading>
            <Text>
              Vaši osobni podaci se koriste za pružanje usluga koje nudi naša platforma i poboljšanje vašeg korisničkog iskustva. Vaši osobni podaci također se koriste u svrhu obrade Vaših zahtjeva i komunikacije s vama o vašim zahtjevima, upitima i drugim pitanjima.
            </Text>
            <Text>
              Osobni podaci se mogu koristiti u svrhu marketinške komunikacije, kao što su obavijesti o posebnim ponudama, novim uslugama i drugim važnim informacijama.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Dijeljenje osobnih podataka
            </Heading>
            <Text>
              Vaši lični podaci se dijele interno u okviru naše platforme, tokom procesa održavanja platforme. Možemo dijeliti Vaše informacije sa drugim korisnicima naše platforme kako bi pružili najbolje korisničko iskustvo. Takođe, možemo da dijelimo Vaše lične podatke sa sljedećim primaocima uključujući ali ne ograničavajući se na:

              <UnorderedList>
                <ListItem>
                  Drugi korisnici ove platforme - Kako bi ponudili najbolje korisničko iskustvo, Vaši lični podaci poput naziva kompanije, broja telefona i drugih mogu biti podijeljeni sa drugim korisnicima ove platforme.
                </ListItem>
                <ListItem>Poslovni partneri - Možemo dijeliti Vaše informacije sa poslovnim partnerima koji učestvuju u našim marketinškim aktivnostima i koji osiguravaju popuste za korisnike, kao i druge oblike pogodnosti.</ListItem>
                <ListItem>Trećim stranama kao partnerima u pružanju usluge - Kako bi smo osigurali najkvaltetniju uslugu, Vaše podatke dijelimo sa našim partnerima u pružanju usluge, koji se ogledaju u čuvanju podataka, procesuiranju raznih zahtjeva, zaštite od prevara i drugo.</ListItem>
                <ListItem>Regulatorni zahtjevi - U slučaju bilo kakvih regulatornih zahtjeva, od strane sudova, poreznih tijela, policijskih organa, tužilaštava ili drugih agencija za provedbu zakona Bosne i Hercegovine, bićemo dužni, na temelju pristiglih zahtjeva, podatke proslijediti tim institucijama, što je i obaveza Društva u skladu sa važećim propisima Bosne i Hercegovine.</ListItem>
              </UnorderedList>
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Sigurnost i integritet osobnih podataka
            </Heading>
            <Text>
              Preduzećemo razumne korake kako bismo osigurali da su lični podaci koje obrađujemo pouzdani za upotrebu kojoj su namijenjeni, tačni, potpuni i aktuelni. Vi ste odgovorni za tačnost svih ličnih podataka koje nam date. Uložićemo razumne napore da održimo tačnost i integritet dobijenih ličnih podataka i da ih po potrebi ažuriramo.

            </Text>

            <Text mt={2}>
              Mi se pridržavamo odgovarajućih fizičkih, proceduralnih, tehničkih, organizacionih i kadrovskih mjera kako bismo osigurali adekvatnu zaštitu Vaših ličnih podataka koje obrađujemo. Naša platforma se pridržava najboljih industrijskih standarda u pogledu zaštite podataka. Naš cilj je da se zaštite Vaši lični podaci od gubitka, neovlaštenog korištenja, pristupa, manipulacije i uništenja. Međutim, nije moguće garantovati 100% sigurnost Vaših ličnih podataka, ali naša platforma konstantno poboljšava svoje procese i preduzima odgovorne mjere kako bi osigurao najbolju moguću zaštitu Vaših ličnih podataka.
            </Text>
            <Text mt={2}>
              Vaši osobni podaci čuvaju se na sigurnim poslužiteljima s ograničenim pristupom.

            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Prava korisnika
            </Heading>
            <Text>
              Imate pravo zatražiti pristup svojim osobnim podacima koje posjedujemo te zatražiti da ispravimo ili obrišemo bilo koje netačne podatke.

              Korisnik ima pravo u bilo kojem trenutku zatražiti brisanje ličnih podataka (pravo na zaborav). Korisnik to može učiniti zahtjevom na e-mail adresu info@etransport.ba podaci će biti izbrisani unutar trideset dana. Zahtjev mora biti upućen sa e-mail adrese na koju je registrovan korisnički račun u cilju sprečavanja zloupotrebe od strane trećih lica.
            </Text>

            <Text mt={2}>
              Kao korisnik naše platforme imate pravo podnošenje prigovora na e-mail kontakt adresu info@etransport.ba
            </Text>
            <Text mt={2}>
              Važno je napomenuti da u određenim okolnostima možemo biti obvezni zadržati određene osobne podatke kako bismo ispunili naše zakonske obveze ili radi odbrane pravnih zahtjeva.
            </Text>

            <Text mt={2}>
              Ako imate bilo kakvih pitanja o obradi vaših osobnih podataka ili ako želite iskoristiti neko od svojih prava, molimo vas da nas kontaktirate putem e-pošte ili na drugi način naveden na našoj platformi.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Kolačići i praćenje
            </Heading>
            <Text>
              Naša platforma koristi kolačiće i druge slične tehnologije za poboljšanje korisničkog iskustva i praćenje statistike upotrebe. Kolačići su male datoteke koje se preuzimaju na vaš uređaj kada pristupite našoj platformi. Ove datoteke se mogu koristiti za praćenje vaše upotrebe naše platforme, kao i za prikupljanje drugih informacija o vašem uređaju, poput IP adrese i vrste preglednika.
            </Text>
            <Text>
              Možete konfigurirati svoj preglednik da odbija kolačiće ili da vam zatraži odobrenje prije nego što ih preuzmete. Međutim, ako odbijete kolačiće, možda nećete moći koristiti sve funkcije naše platforme.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Izmjene Politike privatnosti
            </Heading>
            <Text>
              Naša platforma zadržava pravo da izmijeni ovu Politiku privatnosti u bilo kojem trenutku, a izmijenjeni tekst Politike će biti objavljen na internet stranici naše platforme. Kako biste bili upoznati sa svim izmjenama, preporučujemo da povremeno pregledate ovu Politiku privatnosti. Ukoliko se ne slažete sa izmijenjenom Politikom privatnosti, molimo vas da prestanete koristiti ovu internet stranicu.

              Sve izmjene Politike privatnosti stupaju na snagu odmah nakon objave na internet stranici naše platforme. Ako nastavite koristiti internet stranicu naše platforme nakon stupanja na snagu izmjena, smatra se da potvrđujete i prihvaćate izmijenjenu Politiku privatnosti.
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              Zaključak
            </Heading>
            <Text>
              Ukoliko imate bilo kakva pitanja u vezi ove Politike ili ukoliko želite podnijeti prigovor, to možete učiniti na e-mail: info@etransport.ba
            </Text>


          </Box>
        </Center>
      </Box>

      <Spacer h={'100px'} />

    </>
  )
};

export default Privacy;