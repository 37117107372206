import { Stack, HStack, Link, Box, Text, Icon, Button, Avatar, Tooltip, AvatarBadge, VStack, useToast } from "@chakra-ui/react"
import React from "react"
import { FaBuilding, FaPhone, FaViber } from "react-icons/fa"
import { FiPhoneCall } from "react-icons/fi"
import { useMoreInfoRequestMutation } from "../../../state/services/transportation/requests"

const RequestCompanyInfo = ({ request }) => {
  const toast = useToast();

  const [requestMoreInfo, { isSuccess, isLoading }] = useMoreInfoRequestMutation();

  React.useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Uspješno!',
        description: "Uspješno ste zatražili kontakt. Ukoliko je firma zainteresovana kontaktirati će Vas putem telefona ili e-maila.",
        status: 'success',
        duration: 10000,
        position: 'bottom',
        isClosable: true,
      })
    }
  }, [isSuccess])

  return (
    <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
      <Stack direction={['column', 'row', 'row', 'row']} justifyContent={'space-between'} alignItems={['center']} spacing={[5, 0]}>
        {request?.company == null && <>
          <HStack w={'full'} spacing={5} justifyContent={'space-between'}>
            <HStack spacing={10}>
              <Icon as={FaBuilding} />
              <Text>Firma koja nudi teret ili robu za transport nije prikazala podatke. Ukoliko trebate više informacija, kliknite na dugme desno.</Text>
            </HStack>
            <Button isDisabled={isSuccess || isLoading} py={5} leftIcon={<Icon mr={5} as={FiPhoneCall} />} colorScheme={'gray'} onClick={() => requestMoreInfo(request.id)}>Zatraži kontakt</Button>

          </HStack>
        </>}
        {request?.company &&
          <>
            <Box>
              <HStack spacing={5}>
                <Avatar size={'md'} name={request?.company?.name} src={request?.company?.logoPath}>
                  <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${request?.company?.rating} od 5 na osnovu prethodni ponuda.`}>
                    <AvatarBadge color={'black'} boxSize='2.5em' bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{request?.company?.rating}</AvatarBadge>
                  </Tooltip>
                </Avatar>
                <VStack spacing={0} alignItems={'left'}>
                  <Text fontSize={'md'}>{request?.company?.name}</Text>
                  <Text color={'gray'} fontSize={'sm'}>{request?.company?.city}</Text>
                </VStack>
              </HStack>
            </Box>
            <Box>
              <Text fontSize={'xs'}>Kontakt telefon</Text>
              <Text fontWeight={'bold'}>{request?.company?.phone}</Text>
            </Box>
            <Box>
              <HStack>
                <Tooltip label={'Pozovite firmu na telefon'} bg={'green'} color={'white'} hasArrow>
                  <Button borderRadius={'full'} as={Link} href={`tel:${request?.company?.phone}`} colorScheme={'green'} leftIcon={<Icon as={FaPhone} mr={3} />} aria-label={"Kontakt telefon"}>Pozovite</Button>
                </Tooltip>

                <Tooltip label={'Pozovite firmu na Viber'} bg={'purple'} color={'white'} hasArrow>
                  <Button borderRadius={'full'} ml={4} as={Link} href={`viber://chat?number=${request?.company?.phone}`} colorScheme={'purple'} leftIcon={<Icon as={FaViber} mr={3} />} aria-label={"Poziv na viber"}>Poziv na Viber</Button>
                </Tooltip>
              </HStack>
            </Box>
          </>
        }
      </Stack>
    </Box>
  )
}

export default RequestCompanyInfo;