import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, AvatarGroup, Stack, Center, Thead, Table, Th, Tr, Tbody, Td, Input, InputGroup, InputLeftElement, TableContainer, Show, Divider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Select, useDisclosure, Tag, AvatarBadge } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TDepartmentTechnicalName, TOfferState, TRequestLoadingType } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaEllipsisV, FaEye, FaFire, FaInfo, FaInfoCircle, FaRegCalendarAlt, FaSearch, FaTruck, FaTruckMoving } from 'react-icons/fa';
import { useGetRequestsQuery } from '../../state/services/requests';
import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import Paginator from '../../components/Common/Paginator';
import { Controller } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { useGetTransportationOffersQuery } from '../../state/services/transportation/offers';
import { useGetOffersQuery } from '../../state/services/offers';
import { Filter } from '../Components/Filter';
import { findByCode } from '../../resources/eu_countries';

const Offers = () => {
  const { currentUser } = useSelector((state: RootState) => state.user)
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({})

  const { data: requestsData } = useGetOffersQuery({ per_page: 7, page: currentPage, ...filterData }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const offer = requestsData?.offers[0];
  const truncate = (str, len) => str?.slice?.(0, len);


  return (
    <>
      <Spacer h={'30px'} />

      <Text mb={5} fontSize={'20px'} fontWeight={'light'}>Dostupna vozila za transport robe/tereta</Text>

      <Filter filterData={filterData} setFilterData={setFilterData} setCurrentPage={setCurrentPage} />

      <Show above={'md'}>
        <TableContainer borderRadius={'md'} boxShadow={'base'}>
          <Table boxShadow={'base'} variant={'primary'}>
            <Thead>
              <Tr>
                <Th>Preuzimanje</Th>
                <Th>Dostava</Th>
                <Th>Datum polaska</Th>
                <Th>Opcija utovara</Th>
                <Th>Vozilo</Th>
                <Th>Prijevoznik</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {requestsData?.offers?.map((offer) => (
                <Tr>
                  <Td>
                    <Tooltip hasArrow label={findByCode(offer?.loadingCountry)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={offer?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{findByCode(offer?.loadingCountry)?.label}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{truncate(offer.loadingCity, 30)}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip hasArrow label={findByCode(offer?.unloadingCountry)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={offer?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{findByCode(offer?.unloadingCountry)?.label}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{truncate(offer.unloadingCity, 30)}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Text fontSize={'sm'}>{displayDate(offer.loadingDate)}</Text>
                  </Td>
                  <Td>
                    {offer.loadingType == TRequestLoadingType.FTL && <Tag colorScheme={'green'}>Puni utovar</Tag>}
                    {offer.loadingType == TRequestLoadingType.LTL && <Tag colorScheme={'red'}>Zbirni tr.</Tag>}
                  </Td>
                  <Td>
                    <Tooltip label={<Stack>
                      {offer.vehicles.map((v) => <Text>{v.name}</Text>)}
                    </Stack>} placement={'bottom'} hasArrow>
                      <Image cursor={'pointer'} src={offer?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'48px'} />
                    </Tooltip>
                  </Td>
                  <Td>
                    <HStack spacing={5}>
                      <Avatar size={'sm'} name={offer?.company?.name} src={offer?.company?.logoPath}>
                        <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${offer?.company?.rating} od 5 na osnovu prethodni vožnji.`}>
                          <AvatarBadge boxSize='1.9em' color={'black'} bg={'gold'} fontSize={'10px'} fontWeight={'bold'}>{offer?.company?.rating}</AvatarBadge>
                        </Tooltip>
                      </Avatar>
                      <VStack spacing={0} alignItems={'left'}>
                        <Text fontSize={'sm'}>{offer?.company?.name}</Text>
                        <Text color={'gray'} fontSize={'xs'}>prije {offer?.createdAtBefore}.</Text>
                      </VStack>
                    </HStack>
                  </Td>
                  <Td>
                    <Button leftIcon={<Icon as={FaEye} />} as={ReachLink} to={`/offers/${offer.id}`} size={'sm'} colorScheme={'blue'} variant={'solid'}>Pregled</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Show>

      <Show below={'md'}>
        {
          requestsData?.offers?.map((offer) => (
            <Box mb={5} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} py={5} px={6} borderRadius={'md'} boxShadow={'md'}>
              <HStack spacing={6}>
                <VStack spacing={4}>
                  <CircleFlag countryCode={offer?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                  <Icon as={FaEllipsisV} />
                  <CircleFlag countryCode={offer?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                </VStack>
                <VStack alignItems={'left'}>
                  <HStack spacing={5}>
                    <VStack spacing={0} alignItems={'left'}>
                      <Text>{offer.loadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.loadingAddress}</Text>
                    </VStack>
                  </HStack>
                  <Spacer h={'20px'} />
                  <HStack spacing={5}>
                    <VStack spacing={0} alignItems={'left'}>
                      <Text>{offer.unloadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.unloadingAddress}</Text>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>

              <Divider my={4} />

              <HStack justifyContent={'space-between'}>
                <HStack spacing={5}>
                  <Icon as={FaRegCalendarAlt} />
                  <VStack alignItems={'left'} spacing={0}>
                    <Text fontSize={'sm'}>{displayDate(offer.loadingDate)}</Text>
                    <Text color={'gray'} fontSize={'xs'}>Datum polaska</Text>
                  </VStack>
                </HStack>
                <Tooltip label={<Stack>
                  {offer.vehicles.map((v) => <Text>{v.name}</Text>)}
                </Stack>} placement={'bottom'} hasArrow>
                  <Image cursor={'pointer'} src={offer?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'64px'} />
                </Tooltip>
              </HStack>

              <Spacer h={'20px'} />

              <HStack justifyContent={'space-between'}>
                <Box>
                  {offer.loadingType == TRequestLoadingType.FTL && <Tag size={'sm'} colorScheme={'green'}>FTL - Puni utovar</Tag>}
                  {offer.loadingType == TRequestLoadingType.LTL && <Tag size={'sm'} colorScheme={'red'}>LTL - Zbirni transport</Tag>}
                </Box>
                <Box textAlign={'right'} mt={5}>
                  <Button as={ReachLink} to={`/offers/${offer.id}#view-placement`} size={'sm'} colorScheme={'blue'}>Pregledaj</Button>
                </Box>
              </HStack>
            </Box>
          ))
        }
      </Show>

      <Box py={5} mb={5} textAlign={'left'}>
        <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
      </Box>





    </>
  )
};

export default Offers;